

const DonneeMemoire = [
    {
        id: 1,
        titre: "Test pour s'entrainer",
        explication: "Mémoriser le mot",
        image : 'memoire1.png',
        time : 10,
        exo: [
            {
                typeQuestion : "ferme",
                question: 'Un mot était affiché sur l\'écran.',
                reponse  : true
            },
            {
                typeQuestion : "ouverte",
                question: 'Ecrire le mot',
                reponse  : ['chocolat']
            }
        ]
    },
    {
        id: 2,
        titre: "Test très facile",
        explication: "Mémoriser les mots",
        image : 'memoire2.png',
        time : 30,
        exo: [
            {
                typeQuestion : "ouverte",
                question: 'Ecrivez tous les mots séparés par un saut de ligne',
                reponse  : ['avion','bateau','escale']
            }
        ]
    },
    {
        id: 3,
        titre: 'Test facile sur les couleurs',
        explication : 'Compter les mots et leurs couleurs',
        image : 'memoire3.png',
        time : 30,
        exo: [
            {
                typeQuestion : "ferme",
                question: '3 mots en bleu étaient affichés',
                reponse  : false
            },
            {
                typeQuestion : "ferme",
                question: '3 mots en rouge étaient affichés',
                reponse  : true
            }
        ]
    },
    {
        id: 4,
        titre: "Test difficile",
        explication :'Mémoriser les mots',
        image : 'memoire4.png',
        time : 50,
        exo: [
            {
                typeQuestion : "ferme",
                question: 'Le mot cure était dans la liste des mots.',
                reponse  : false
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire tous les mots de plus de 4 lettres (sauter une ligne entre chaque mot)',
                reponse  : ['cabane', 'cachet']
            }
        ]
    },
    {
        id: 5,
        titre: "Test avec 4 mots",
        explication : 'Mémoriser les mots',
        image : 'memoire5.png',
        time : 50,
        exo: [
            {
                typeQuestion : "ferme",
                question: 'Il y a 2 mots commençant par P.',
                reponse  : false
            },
            {
                typeQuestion : "ferme",
                question: 'Il y a 1 mot contenant un Y.',
                reponse  : true
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot le plus court',
                reponse  : ['été']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot qui comprend 2 A',
                reponse  : ['paysager']
            }

        ]
    },
    {
        id: 6,
        titre: "Nouveau test avec 4 mots",
        explication: 'Mémoriser les mots en corrigeant la faute',
        image : 'memoire6.png',
        time : 50,
        exo: [
            {
                typeQuestion : "ferme",
                question: 'Il y a un seul mot commençant par un T.',
                reponse  : true
            },
            {
                typeQuestion : "ferme",
                question: 'Il y a 3 mots avec des accents.',
                reponse  : false
            },
            {
                typeQuestion : "ouverte",
                question: 'Corriger le mot avec une faute d\'orthographe',
                reponse  : ['alphabet']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot qui se termine par QUE',
                reponse  : ['pétanque']
            }

        ]
    },
    {
        id: 7,
        titre: "Test difficile avec 5 mots",
        explication: 'Mémoriser les mots et la couleur des mots',
        image : 'memoire7.png',
        time : 70,
        exo: [
            {
                typeQuestion : "ferme",
                question: 'Tous les mots de la liste ont 6 lettres.',
                reponse  : true
            },
            {
                typeQuestion : "ferme",
                question: 'Il y a 2 mots avec des accents aigus.',
                reponse  : false
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot en rouge',
                reponse  : ['baquet']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire les mots qui commencent par T (sauter une ligne entre chaque mot)',
                reponse  : ['tympan', 'tétine']
            },


        ]
    }
    ,
    {
        id: 8,
        titre: "Type des mots - très difficile",
        explication :'Mémoriser les mots, les couleurs et les synonymes, homonynes, antonymes',
        image : 'memoire8.png',
        time : 70,
        exo: [
            {
                typeQuestion : "ferme",
                question: 'Il y a 2 mots écrits en rouge.',
                reponse  : true
            },
            {
                typeQuestion : "ferme",
                question: 'Il y a 2 mots de 3 lettres.',
                reponse  : false
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire les 2 homonymes (sauter une ligne entre chaque mot)',
                reponse  : ['mer', 'maire']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire les 2 synonymes',
                reponse  : ['abhorrer', 'détester']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire les 2 antonymes',
                reponse  : ['chaud', 'froid']
            },


        ]
    },
    {
        id: 9,
        titre: "Mots Coupés 1 - facile",
        explication : 'Mémoriser les 3 mots coupés possibles',
        image : 'memoire9.png',
        time : 60,
        exo: [
            {
                typeQuestion : "ouverte",
                question: 'Écrire les mots possibles commençant par C (sauter une ligne entre chaque mot)',
                reponse  : ['clique', 'cloque']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot possible commençant par un T',
                reponse  : ['trique']
            }
        ]
    },
    {
        id: 10,
        titre: "Mots Coupés 2 - moyen",
        explication : 'Trouver les 3 mots coupés et les mémoriser',
        image : 'memoire10.png',
        time : 70,
        exo: [
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot contentant le plus de lettres',
                reponse  : ['cascade']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot commençant par un A',
                reponse  : ['aubade']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot finissant par un R',
                reponse  : ['casseur']
            }
        ]
    },
    {
        id: 11,
        titre: "Mots Coupés 3 - très difficile",
        explication : 'Trouver tous les mots coupés ou non et les mémoriser',
        image : 'memoire11.png',
        time : 80,
        exo: [
            {
                typeQuestion : "ouverte",
                question: 'Écrire les 2 mots de 4 lettres',
                reponse  : ['bord', 'rade']
            },
            {
                typeQuestion : "ferme",
                question: 'Il y avait 1 note de musique',
                reponse  : true
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire les 2 mots possibles de 6 lettres',
                reponse  : ['flacon', 'dorade']
            }
        ]
    },
    {
        id: 12,
        titre: "Mots Coupés 4 - très difficile",
        explication : 'Mémoriser tous les mots possibles avec leur couleur',
        image : 'memoire12.png',
        time : 70,
        exo: [
            {
                typeQuestion : "ferme",
                question: 'Tous les groupes de lettres sont composés de 3 lettres.',
                reponse  : true
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot en regroupant les lettres rouges',
                reponse  : ['goulot']
            },
            {
                typeQuestion : "ouverte",
                question: 'Écrire le mot en regroupant les lettres noires',
                reponse  : ['boulet']
            },
            {
                typeQuestion : "ouverte",
                question: 'Ecrire les 2 mots possibles en mélangeant les lettres noires et rouges (sauter une ligne entre chaque mot)',
                reponse  : ['goulet', 'boulot']
            },


        ]
    }




];

export default DonneeMemoire;
