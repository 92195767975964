
import React, { Component } from 'react';
import { donneesSuivantType } from '../../data/AccesDonnees';
import { Link } from 'react-router-dom';
import { Tag, Card } from 'antd';
import {readLocalStorage} from '../commun/localStorage';


export default class SousMenu extends Component {

    constructor(props)
    {
        super(props);
        this.type = props.type;
        this.donnees = donneesSuivantType(this.type);
        if (this.type === 'defijeux' || this.type === "defigrammaire") this.type = "defiorthographe";
    }
    
    render()
    {
       
     
        const dejaFait = readLocalStorage(this.type); 
        return (   <Card title={this.props.titre}>
        <div>
        {this.donnees.map((v, i) =>  <React.Fragment  key={i}>
            {this.props.supprimer !== v.id &&<div className="margeLien" key={i}><Link reloadDocument to={'/' + this.type + '/' + v.id}>{v.titre}</Link>{(dejaFait !== null && dejaFait.includes(v.id))&&<Tag color="magenta" className="tag">déjà fait</Tag>}</div>}
            </React.Fragment> )}
    </div>
        </Card>)
    }
    }
    
    SousMenu.defaultProps = {
      supprimer : 'rien',
      titre: 'Les tests'
    };