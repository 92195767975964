import React, { Component } from 'react';
import withRouter from './withRouter';
import { Row, Col, Card, Avatar } from 'antd';
import obtenirDonneesJeu from '../../data/AccesDonnees';
import laurier from '../../images/laurier.png'
import borderHonneur from '../../images/borderHonneur.png';


const { Meta } = Card;
class Tableau extends Component {

  constructor(props) {
    super(props);
    this.state = { resultats: [] }
    this.type = props.type;
    if (this.type === undefined) this.type = 'dictee';
  }

  chercherTitre = (noExo) => {

    this.donnees = obtenirDonneesJeu(noExo, this.type);
    if (this.donnees != null) {
      let titre = this.donnees.titre;
      return titre.length > 38 ? titre.slice(0, 38) + '...' : titre;
    }

    return '';

  }

  componentDidMount() {
  

    let url = new URL(process.env.REACT_APP_URL_TABLEAU);
    var data = new FormData();
    data.append('type', this.type);
    fetch(url, {
      method: "POST",
      body: data
    }).then(res => res.json())
      .then(res => {
        this.setState({ resultats: res });
      }
      );


  }
  render() {
    return <div className="espaceHaut" >
      <div className="centre espaceTitreBas">

        <h1>Le tableau d'honneur</h1>
        <div className="centre espaceTitreBas"><img src={borderHonneur} alt="bordure"  width="100" height="40" ></img></div>
      </div>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        {this.state.resultats.map((info, i) => <Col key={i} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 6 }}><Card className="hauteurCarte">
          <Meta
            avatar={<Avatar src={laurier} alt="récompense" />}
            title={<span className="couleurHonneur">{info.prenom.length > 15 ? info.prenom.slice(0, 15) : info.prenom}</span>}
          />
          <p>0 faute </p>
          <p>{this.chercherTitre(info.noExo)}</p>
          <p>le {info.date}</p>
        </Card> </Col>)}
      </Row>
    </div>
  }
}

export default withRouter(Tableau);
