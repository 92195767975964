
import React, { Component, Suspense } from 'react';
import { ConfigProvider } from 'antd';


import ReactGA4 from "react-ga4";
import { Helmet } from 'react-helmet';
import { router } from './Routeur';
import { RouterProvider,} from "react-router-dom";
import '../../style/style.css'



export default class App extends Component {

  constructor() {
    super();
  
    ReactGA4.initialize("G-916D7SW5D6", { legacyDimensionMetric: false, gtagOptions: { send_page_view: true } })

  }

  

  render() {
    return (
      <div className="App">
    
         <Helmet>
          <title>Les tests d'orthographe</title>
          <meta name="description" content="Evaluer son niveau d'orthographe avec des tests. Tester son niveau en orthographe grâce à des dictées, des jeux..." />
        </Helmet>
        <ConfigProvider
    theme={{
      token: {
         fontSize: '16px',
      },
      components: {
        Menu: {
          colorItemBg: '#afe4e2',
        }}
    }}
  >
      
        
          
         {/*   <Header><Menu items={itemsMenu} mode="horizontal" ></Menu></Header> */}
  
        
         <Suspense fallback={<div>En cours de chargement...</div>}>
          <RouterProvider router={router} >
            </RouterProvider>
            </Suspense>
        </ConfigProvider>
      </div>
      
    );
  }
}

