 const DonneeDictee = [
    {
        id : 1,
        titre : "Une dictée niveau facile (27 mots)",
        fichier : 'dictee1.mp3',
        aide : "Le narrateur est un homme. Notez les deux orthographes : J'étais sûr qu'il était sur la table. ",
        texte : 'Le loup est dans la cour. C\'est un grand loup avec un nez noir. Il est très maigre. Je suis sûr qu\'il cherche à manger notre coq.'
    },
    {
        id : 2,
        titre : "Une autre dictée facile (25 mots)",
        fichier : 'dictee2.mp3',
        aide: "Attention, la dictée est facile mais la plupart des erreurs proviennent de la ponctuation, il faut écrire le mot ensuite le point puis l'espace (comme on le fait normalement).",
        texte : 'Je vais souvent me promener sous la pluie avec mes bottes et un chapeau de pluie. Je regarde l\'eau du chemin. Elle va jusqu\'au moulin.'
    },
    {
        id : 7,
        titre : "Dictéee facile du certificat d'étude de 1965 (47 mots)",
        fichier : 'dictee8.mp3',
        aide: "Dans la première phrase avec Marie on peut hésiter entre le singulier et le pluriel, il faut utiliser le singulier.",
        texte : 'Marie se sentait heureuse de retourner au champ. Le vieux chien l\'accompagnait. Les vaches marchaient en une longue file docile et lente. La première en tête, la plus ancienne de la ferme, les menait toutes. C\'était une grande vache rouge et blanche avec de hautes pattes maigres.'
        
    },
    {
        id : 3,
        titre : "Dictée CM2 évaluation nationale 2013 (34 mots)",
        fichier : 'dictee3.mp3',
        texte : 'Lorsque les garçons étaient plus jeunes, leur grand-mère leur racontait souvent des histoires d\'autrefois. Les enfants se demandaient toujours si elle inventait pour eux ces récits merveilleux ou si elle les savait depuis longtemps.'
    },
    {
        id : 4,
        titre : "Dictée CM2 évaluation nationale 2012 (49 mots)",
        fichier : 'dictee4.mp3',
        texte : 'Pendant les dernières grandes vacances, au mois de juillet, toute la famille de Pierre a visité un zoo. Les lions étaient un peu endormis, ce sont des bêtes féroces qui ressemblent alors à des grosses peluches. Mais quand une lionne a soudain rugi, les visiteurs ont eu peur.'
    },
    {
        id : 5,
        titre : "Dictée niveau troisième - brevet 2019 (72 mots)",
        fichier : 'dictee5.mp3',
        texte : 'Dès qu\'ils étaient au complet, ils partaient, promenant la raquette le long des grilles rouillées des jardins devant les maisons, avec un grand bruit qui réveillait le quartier et faisait bondir les chats endormis sous les glycines poussiéreuses. Ils couraient, traversant la rue, essayant de s’attraper, couverts déjà d’une bonne sueur, mais toujours dans la même direction, vers le champ, non loin de leur école, à quatre ou cinq rues de là.'
    },
    {
        id : 6,
        titre : "Dictée niveau troisième - brevet des collèges (42 mots)",
        aide : "Rappel sur le point d'interrogation : texte[espace]?[espace]texte. On met une majuscule au mot qui suit le point d'interrogation si l'on considère que le point d'interrogation termine la phrase.  Dans une date, seul le mois s’écrit en toutes lettres.",
        fichier : 'dictee9.mp3',
        texte : 'D\'où venait cet aérostat, véritable jouet de l\'effroyable tempête ? De quel point du monde s\'était-il élancé ? Il n\'avait évidemment pas pu partir pendant l\'ouragan. Or l\'ouragan durait depuis cinq jours déjà et ses premiers symptômes s\'étaient manifestés le 18 mars.'
    },
  
    {
        id : 8,
        titre : "Dictée d’entrée en sixième en 1954 (58 mots)",
        fichier : 'dictee6.mp3',
        texte : 'Pas une feuille ne bougeait, et dans ces beaux jardins que j\'avais sous les yeux, les vignes espacées sur les pentes, au grand soleil qui fait les vins sucrés, les fruits d\'Europe abrités dans un coin d\'ombre, les petits orangers, les mandariniers en longues files microscopiques, tout gardait le même aspect morne, cette immobilité des feuilles attendant l\'orage.'
        
    },
    {
        id : 9,
        titre : "Dictée du Certificat d’études primaire de 1912 (53 mots)",
        fichier : 'dictee7.mp3',
        aide: "Bucheron peut s'écrire bûcheron ou bucheron, selon les rectifications orthographiques de 1990. Ici, nous n'acceptons que la version bucheron avec la nouvelle orthographe (30 ans déjà).",
        texte : 'Il est mort, le grand chêne, le roi de la forêt. La cognée du bucheron l\'a abattu dans la clairière où il se dressait solitaire et majestueux. Au bout du tronçon qui reste seul à la place où le tronc s\'élevait, la sève, qui est le sang des plantes, monte et afflue encore.' 
    },
    {
        id : 10,
        titre : "Dictée classique avec quelques pièges (56 mots)",
        fichier : 'dictee10.mp3',
        aide: "Rappel sur le point d'exclamation : texte[espace]![espace]texte. Attention au participe passé employé avec le verbe avoir.",
        texte : 'Je m\'adresse à vous pour vous faire part d\'un événement intéressant mais bouleversant. La voiture, accidentée dans un champ de maïs, que j\'ai aperçue cette nuit dans mon cauchemar était la vôtre ! Or, malgré quelques troubles psychologiques, je fais encore partie des gens lucides. Mais je n\'ai pas sorti ma plume pour développer ce point.'
        
    },
    {
        id : 11,
        titre : "Dictée extraite du championnat suisse 2019 (difficile - 61 mots)",
        fichier : 'dictee11.mp3',
        aide : "Bien écouter la phrase au départ pour repérer les sujets des verbes.",
        texte : 'Là où triomphaient naguère la symétrie, l\'ordre et la mesure régnaient la confusion, l\'enchevêtrement et la luxuriance. Il n\'y avait plus ni parterre ni plate-bande. J\'avais l\'impression que la nature s\'était plu à ensevelir mes souvenirs dans un linceul d\'herbes folles. Les orties interdisaient l\'accès à la cabane où nous avions couru cacher notre chagrin à la mort de notre grand-mère.'
        
    },
    {
        id : 12,
        titre : "Dictée assez difficile de Bernard Pivot (40 mots)",
        fichier : 'dictee12.mp3',
        texte : 'Elle coule, roule, s\'enroule, la Loire, tendre ou impétueuse, entre vals et près. Ni les donjons qui s\'y sont reflétés, ni les gentes dames qui s\'y sont mirées, ni les amoureux qui y ont canoté n\'ont réussi à la canaliser.'
        
    },
    {
        id : 13,
        titre : "Nouveau - Dictée officielle évaluation CM2 (66 mots).  10,7 fautes en moyenne en 1987 - 19,4 en 2021.",
        fichier : 'dictee42.mp3',
        texte : "Le soir tombait. Papa et maman, inquiets, se demandaient pourquoi leurs quatre garçons n'étaient pas rentrés. Les gamins se sont certainement perdus. S'ils n'ont pas encore retrouvé leur chemin, nous les verrons arriver très fatigués à la maison. Pourquoi ne pas téléphoner à Martine ? Elle les a peut-être vus. Aussitôt dit, aussitôt fait, à ce moment, le chien se mit à aboyer."
        
    }






];
export default DonneeDictee;
