
const DonneeListeMots = [{
    id: 1,
    taille : 6,
    titre: "Mots de 6 lettres",
   liste: ['ACAJOU', 'AMORCE', 'BARQUE', 'BATEAU', 'BETISE', 'BOURSE', 'CANAPE', 'CARNET', 'DEVOIR', 'DISQUE', 'ECUEIL','ENFANT',
'FARINE','FLEUVE', 'GELULE','HELIUM','IVOIRE', 'JAMBON', 'LARDON', 'LEURRE', 'MILIEU', 'MUGUET','NOTICE', 'OURSON', 'PASTEL',
'PERRON', 'PEUPLE', 'RAGOUT', 'RAPACE', 'REVEUR', 'SERMON', 'SILLON', 'TARTRE', 'TOUBIB', 'URGENT', 'VALLON', 'ZENITH']

},
{
id: 2,
taille : 7,
titre: "Mots de 7 lettres",
liste: ['ABSENCE', 'AFFAIRE', 'AROMATE', 'BAGNARD', 'BARATIN', 'BISCUIT', 'BOUQUET', 'CAILLOU','CARAMEL', 'CLAVIER', 'CONSOLE',
'DAUPHIN', 'DEFICIT', 'DORMEUR', 'DRAPEAU', 'EPARGNE', 'FISSURE', 'GLUCIDE', 'HORMONE', 'INSECTE', 'JUMELLE', 'LECTEUR','LUTHIER',
'MACHINE', 'MAILLOT', 'MISSION', 'NATUREL', 'ORIGINE', 'PELICAN', 'PINTADE', 'RELIURE', 'SOUDEUR', 'SUSPENS', 'TEMPETE', 'TILLEUL', 'VANILLE',
'VOYANTE']
},
{
    id: 3,
    taille : 8,
    titre: "Mots de 8 lettres",
    liste: ['ALLIANCE', 'ARCHIPEL', 'ASSIETTE', 'BAIGNEUR', 'BENEVOLE', 'CALOMNIE', 'CHANTEUR', 'CITATION', 'COLLEGUE', 'DEMINEUR',
'DISCOURS', 'ERAFLURE', 'ETUDIANT', 'GRIFFURE', 'GRATTOIR','HERITAGE', 'KEROSENE', 'LENTILLE', 'LINGERIE', 'MANUCURE', 'MIGRAINE',
'MOQUETTE', 'OBSTACLE', 'OVERDOSE', 'PANTHERE', 'PEINTURE', 'PIANISTE', 'REGIMENT', 'RICOCHET', 'SPECIMEN', 'STOCKAGE', 'TOILETTE', 'TONNERRE',
'VINAIGRE']
    },
    {
        id: 4,
        taille : 7,
        titre: "Mots de 7 lettres - difficile",
        liste: ['ABOYEUR', 'ACOLYTE', 'ADHESIF','AQUEDUC', 'BAROQUE', 'BECARRE', 'BRIQUET', 'BROYAGE', 'CALYPSO',
    'COHORTE', 'CUISSOT', 'DACTYLO', 'DIOXYDE', 'ESCARPE', 'GABELLE', 'GALAXIE', 'GYNECEE', 'JACUZZI', 'KOUGLOF', 'MIXTURE','METEQUE',
    'OEILLET','PARAPHE','PERDRIX','PIVOINE','REJETON','ROUQUIN','SAMOVAR','SOLVANT','TREMOLO', 'VARAPPE','VOILAGE','ZIZANIE', 'WALLABY'
]
        },
]

export default DonneeListeMots;
