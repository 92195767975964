let donneesConcoursJeux =
    [
        {
            id: 10,
            titre: "Jouer avec les mots - facile",

            liste: [{ type: 'listemots', id: 10010, titre: "pyramide" },{ type: 'motdefinition', id: 10011, titre: "définition" }, { type: 'listemots', id: 10012, titre: "pyramide" }
            ]
        },
        {
            id: 140,
            titre: "Des jeux avec les mots - facile",

            liste: [{ type: 'listemots', id: 10140, titre: "pyramide" },{ type: 'motdefinition', id: 10141, titre: "définition" }, { type: 'mastermind-mot', id: 10142, titre: "motus" }
            ]
        },
        {
            id: 20,
            titre: "Jouer avec les mots - intermédiaire",

            liste: [{ type: 'listemots', id: 10020, titre: "pyramide" },{ type: 'motdefinition', id: 10021, titre: "définition" }, { type: 'listemots', id: 10022, titre: "pyramide" }
            ]
        },
        {
            id: 80,
            titre: "Jouer avec les mots - difficile",

            liste: [{ type: 'listemots', id: 10080, titre: "pyramide" },{ type: 'motdefinition', id: 10081, titre: "définition" }, { type: 'listemots', id: 10082, titre: "pyramide" }
            ]
        }, {
            id: 100,
            titre: "Jeux de mots (motus)  - facile",

            liste: [{ type: 'mastermind-mot', id: 10100, titre: "motus" }, { type: 'listemots', id: 10101, titre: "pyramide" }, { type: 'mastermind-mot', id: 10102, titre: "motus" }
            ]
        },
        {
            id: 110,
            titre: "Jeux de mots (motus) - intermédiaire",

            liste: [{ type: 'mastermind-mot', id: 10110, titre: "motus" }, { type: 'listemots', id: 10111, titre: "pyramide" }, { type: 'mastermind-mot', id: 10112, titre: "motus" }
            ]
        },
        {
            id: 150,
            titre: "Pyramide des images en désordre - facile",
            liste: [  { type: 'desordre', id: 10150, titre: "désordre" },{ type: 'listemots', id: 10151, titre: "pyramide" }, { type: 'desordre', id: 10152, titre: "désordre" }
            ]
        },
        {
            id: 160,
            titre: "Pyramide des images en désordre - intermédiaire",
            liste: [  { type: 'desordre', id: 10160, titre: "désordre" },{ type: 'listemots', id: 10161, titre: "pyramide" }, { type: 'desordre', id: 10162, titre: "désordre" }
            ]
        },
        {
            id: 300,
            titre: "Pyramide des images  - moyen",
            liste: [  { type: 'listemots', id: 10300, titre: "pyramide" },{ type: 'listemots', id: 10301, titre: "pyramide" }]
            
        },
        {
            id: 190,
            titre: "Des hautes pyramides - moyen",
            liste: [  { type: 'listemots', id: 10190, titre: "pyramide" },{ type: 'listemots', id: 10191, titre: "pyramide" }, { type: 'listemots', id: 10192, titre: "pyramide" }, { type: 'listemots', id: 10193, titre: "pyramide" }
            ]
        },
        {
            id: 250,
            titre: "Une autre pyramide à construire - facile",
            liste: [  { type: 'listemots', id: 10250, titre: "pyramide" },{ type: 'listemots', id: 10251, titre: "images" }, { type: 'listemots', id: 10252, titre: "pyramide" }, { type: 'listemots', id: 10253, titre: "images" }
            ]
        },
        {
            id: 220,
            titre: "Test de culture générale mélangée 1 - intermédiaire",
            liste: [  { type: 'qcmmelange', id: 10220, titre: "culture" },{ type: 'qcmmelange', id: 10221, titre: "culture" }]
            
        },
        {
            id: 230,
            titre: "Test de culture générale mélangée 2 - difficile",
            liste: [  { type: 'qcmmelange', id: 10230, titre: "culture" },{ type: 'qcmmelange', id: 10231, titre: "culture" }]
            
        },
        {
            id: 240,
            titre: "Test de culture générale mélangée 3 - difficile",
            liste: [  { type: 'qcmmelange', id: 10240, titre: "culture" },{ type: 'qcmmelange', id: 10241, titre: "culture" }]
            
        },
      
       
        
    ]

    export default donneesConcoursJeux;