import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Ad from '../commun/adSense';
import { Link } from 'react-router-dom';
import border from '../../images/border.png'
import concours from '../../images/concours.png';
import orthographe from '../../images/orthographe.png';
import presentation from '../../images/presentation.webp'
import { Helmet } from 'react-helmet';
import Tableau from '../commun/Tableau';


export default class Presentation extends Component
{

 
    render()
    {
        return(
        <div>
           <Helmet>
           <title>evalquiz : le site numéro 1 pour tester et évaluer votre orthographe  </title>
          <meta name="description" content="Le meilleur site français pour évaluer et tester son niveau d'orthographe, de grammaire ou de conjugaison, les exercices sont classés par niveaux (du simple au difficile), ils sont accessibles gratuitement en ligne pour les enfants ou les adultes." />
        </Helmet>
          <div className="centre couleur grandTitre">S'amuser avec les mots, l'orthographe et la grammaire</div>
          <div className='centre espaceTitreBas'>evalquiz : le site numéro 1 du divertissement intelligent</div>
          <Row><p>Améliorez votre orthographe grâce à des dizaines de tests de différents styles. Tous sont gratuits et ne nécessitent aucune inscription.
          La difficulté des tests est indiquée la plupart du temps, tout le monde peut jouer de 9 à 99 ans, les adultes comme les enfants. Amusez-vous bien !</p></Row>
<div className="presImages">
          <div><a href="https://concours.evalquiz.com/presreflexion"><img className="resp" src={concours}  alt="jeu vocabulaire" width="400" height="225" ></img></a></div>
          <div><a href="https://concours.evalquiz.com/presorthographe"><img className="resp" src={orthographe}  alt="test orthographe" width="400" height="225" ></img></a></div>
     </div> 
            <Ad></Ad>
          <div className="centre"><img src={border} alt="bordure"  width="100" height="51" ></img></div>
     
          <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Nos défis</h1>
            <p>Un défi est un ensemble de petits jeux (dictée, phrase à trou, conjugaison...), chaque jeu a un objectif qu'il faut impérativement atteindre pour passer au jeu suivant. Si vous réussissez tous les jeux du défi, vous gagnez le défi. </p>
            <p>Seul les meilleur(e)s peuvent réussir un défi à la première tentative, ceux qui réussissent cet exploit verront leurs noms inscrits sur notre tableau d'honneur.</p>
           <p>Pour vous préparer aux défis n'hésitez pas à vous entrainer avec tous les exercices présents sur cette page.</p>
             
          </Col>
        <Col md={10}>
          <SousMenu type='defigrammaire' titre="Les défis de l'orthographe, de la grammaire et de la conjugaison"></SousMenu>
          <div className='espaceHaut'></div>
          <SousMenu type='defijeux' titre="Les défis des jeux de lettres et de mots"></SousMenu>
        </Col>
        </Row>
     

        <div className="centre"><img src={border} alt="bordure"  width="100" height="51"></img></div>
<div className="couleur titre centre">Les tests</div>
    <Row className="margeHaut">
    <Col xs={24} sm={12}><Link to="/dictees"><div className="tuile">Les dictées</div></Link></Col>
    <Col xs={24} sm={12}><Link to="/grammaire-orthographe"><div className="tuile">Grammaire / Orthographe</div></Link></Col>
    <Col xs={24} sm={12}><Link to="/conjugaison"><div className="tuile">Conjugaison</div></Link></Col>
    <Col xs={24} sm={12}><Link to="/jeux-mots"><div className="tuile">Jouer avec les mots</div></Link></Col>
    </Row>

    <div className="centre"><img className="resp" src={presentation} alt="presentation" width="1000" ></img></div>
    <Ad></Ad>
    <Row>
      <Col xs={24}>
      <div className='couleur titre centre'>Les défis</div>
      <p className='centre'>1500 personnes ont déjà réussi à inscrire leurs noms au tableau d'honneur. Les 5 meilleurs de tous les temps : Gwendal, Valérie, Marie, Yasmina et Sylvie.</p>
      </Col>   </Row>
        <Tableau type="defiorthographe"></Tableau>
     
      
      <div className="centre"><img src={border} alt="bordure"  width="100" height="51"></img></div>

     
        <Row className="espaceHaut">
           <p>Quelquefois plusieurs orthographes sont possibles pour un mot, le programme ne prend en compte qu'une seule orthographe. Le problème se pose particulièrement avec les accents circonflexes sur les u et i.
          Nous essayons sur ce site de ne prendre en compte que la nouvelle orthographe sans accent circonflexe (plus simple sur un clavier).</p>
          <p>L'orthographe n'est pas une science exacte et l'homme n'est pas infaillible, vous pouvez nous contacter directement si vous avez des remarques ou des propositions en consultant la page des <a href="https://evalquiz.com/home/faq">mentions légales.</a></p>
          </Row>
          <h2>Crédits</h2>
          <p>Les icônes de bordure sont l'oeuvre d'<a href="https://thenounproject.com/zzyzz/">Olena Panasovska.</a></p>
          <p>L'icône des lauriers est l'oeuvre d'<a href="https://thenounproject.com/AliceNoir/">Alice Noir.</a></p>
         <p>Photo de présentation d'Alexandr Podvalny provenant de Pexels</p>
     
          <Row className="espaceHaut"></Row>
        </div>)
    }

}
