

const DonneePhraseTrou = [
    {
        id : 1,
        titre : "Une test niveau CE1",
        texte : "Bruno, Marie et Éric partent se promener dans la campagne. Pour le [piknik] @pique-nique , Bruno a apporté le reste du [roti] @rôti , une orange et du [Sòkòla] @chocolat . "
    },
    {
        id : 2,
        titre : "Une autre phrase niveau CE1",
        texte : "Le dimanche, quand le temps est beau, nous allons nous [pròmné] @promener au parc. Les enfants sont [kôtâ] @contents et jouent autour du bassin. Nous donnons des [mjèt] @miettes de pain aux canards."
    },
    {
        id : 3,
        titre : "Phrase à compléter niveau CM2 - facile",
        texte : "Dès qu'il s’éveille, à l’aube, il se met à l’ouvrage. Il fabrique un arc avec une branche souple et une corde en fibre de liane. Il taille quelques [flèS] @flèches et part à la chasse. Le dieu Quals le protège : Lynx tue une multitude d’oiseaux. Il les [dévòr] @dévore . De leur plumage multicolore, il se fait un manteau si beau, si [ébluisâ] @éblouissant que le soleil traversant le ciel s’arrête un instant, en plein midi, pour le contempler."   
    },
    {
        id : 5,
        titre : "Phrase à compléter niveau CM2 - facile",
        texte : "La boutique de Franz qui était située près de la maison [resâblè] @ressemblait à une merveilleuse caverne d’Ali Baba. Elle était remplie avec des jouets extraordinaires et magiques que nous admirions avec [âvi] @envie . Les enfants adoraient un lieu aussi [féérik] @féérique . Celui-ci avait le pouvoir de les séduire et il mettait régulièrement dans leurs yeux des étoiles d’émerveillement."   
    },
    {
        id : 6,
        titre : "Connaissez-vous les proverbes ? - facile",
        texte : "Pierre qui roule n'amasse pas @mousse .<br>Au royaume des aveugles, les borgnes sont @rois .<br>C'est à force de forger que l'on devient @forgeron .<br> A l’impossible nul n’est @tenu .<br> Noël au balcon, Pâques au @tison .<br>Qui s'acquitte @s'enrichit .<br>La pluie du matin n'arrête pas le @pèlerin .<br>On n'est jamais mieux servi que par @soi-même .<br>Mieux vaut être seul que mal @accompagné .<br>"   
    },
    {
        id : 7,
        titre : "De bien belles pensées et citations à méditer - assez facile",
        texte : "Aimer, ce n'est pas se regarder l'un l'autre, c'est regarder @ensemble dans la même direction.  (Antoine de Saint-Exupéry)<br>L'amour @triomphe de tout. (Virgile)<br> La vie, c'est comme une @bicyclette , il faut avancer pour ne pas perdre l'équilibre. (Albert Einstein)<br>Je ne perds jamais. Soit je gagne, soit j’ @apprends . (Nelson Mandela)<br>La vie est comme une pièce de théâtre: ce qui compte ce n'est pas qu'elle dure @longtemps , mais qu'elle soit bien jouée. (Sénèque)<br>Une femme pardonne tout, @excepté qu’on ne veuille pas d’elle (Musset).<br>"   
    },
    {
        id : 8,
        titre : "Connaissez-vous le Corbeau et le Renard ?",
        extrait : "Le Corbeau et le Renard -  Jean de La Fontaine",
        texte : "Maître Corbeau, sur un arbre @perché ,<br>Tenait en son bec un fromage.<br>Maître Renard, par l'odeur alléché,<br>Lui tint à peu près ce langage :<br>Et bonjour, Monsieur du Corbeau.<br>Que vous êtes joli ! que vous me semblez beau !<br>Sans mentir, si votre @ramage <br>Se rapporte à votre @plumage ,<br>Vous êtes le Phénix des hôtes de ces bois.<br>À ces mots, le Corbeau ne se sent pas de joie ;<br>Et pour montrer sa belle voix,<br>Il ouvre un large bec, laisse tomber sa proie.<br>Le Renard s'en saisit, et dit : Mon bon Monsieur,<br>Apprenez que tout @flatteur <br>Vit aux dépens de celui qui l'écoute.<br>Cette leçon vaut bien un @fromage , sans doute.<br>Le Corbeau @honteux et confus<br>Jura, mais un peu tard, qu'on ne l'y prendrait plus.<br>"   
    },
    {
        id : 9,
        titre : "Connaissez-vous ce magnifique poème de Victor Hugo ?",
        extrait : " Demain, dès l’aube…  Victor Hugo ( Les Contemplations)",
        texte : "Demain, dès l'aube, à l'heure où blanchit la campagne,<br>Je partirai. Vois-tu, je sais que tu m'attends.<br>J'irai par la forêt, j'irai par la montagne.<br>Je ne puis demeurer loin de toi plus longtemps.<br>Je marcherai les yeux fixés sur mes pensées,<br>Sans rien voir au dehors, sans entendre aucun bruit,<br>Seul, inconnu, le dos courbé, les mains croisées,<br>Triste, et le jour pour moi sera comme la nuit.<br>Je ne regarderai ni l’or du soir qui tombe,<br>Ni les voiles au loin descendant vers Harfleur,<br>Et quand j'arriverai, je mettrai sur ta @tombe <br>Un bouquet de houx vert et de bruyère en fleur.<br>"
    },
    {
        id : 10,
        titre : "La concordance des modes - facile",
        texte : "Au fait, hier soir, je t’ai attendu dehors jusqu’à ce qu’il se [mettre] @mette à pleuvoir. À ce moment-là, je me suis réfugié dans un café, si bien que nous nous [être] @sommes ratés, si toutefois tu es venu. Ne répète à personne ce que je t’[avoir] @ai dit au sujet de Sébastien : on pourrait en déduire que je [être] @suis jaloux. Non, ce que je t’[avoir] @ai dit, c’est pour que tu [être] @sois prévenu et que tu ne te [faire] @fasses pas avoir, toi aussi, par Sébastien.<br>"
    },
    {
        id : 11,
        titre : "Compléter la phrase rokoko - difficile",
        extrait : "La confidente",
        texte : "Chaque dimanche, je me rends rue des Bouleaux pour entendre ma grand-mère [patèrnèl] @paternelle me raconter de nouvelles histoires. Ses souvenirs d’une époque socioculturelle [révòlu] @révolue me fascinent et m’enchantent. Ma grand-mère a vécu, durant sa jeunesse, un fol amour dont elle ne s’est jamais complètement remise. Souvent, confortablement [unstalé] @installées dans son boudoir rempli d’objets [ròkòko] @rococo , accoudées sur un mol oreiller et dégustant des boissons [ègredousse] @aigres-douces , nous laissons l’après-midi filer par les fenêtres grandes ouvertes alors qu’elle me raconte ses anciennes amours avec un [bèl] @bel officier de la marine. Seuls les beaux instants sont évoqués ; les événements désagréables sont [tabu] @tabous .<br>"
    },
    {
        id : 12,
        titre : "Compléter des verbes - facile",
        texte : "Je me [souvjên] @souviens de Paul alors qu’il n’avait que six ans. Déjà, ses parents l’encourageaient à développer son potentiel artistique. Tout le monde [disè] @disait de lui qu’il deviendrait un grand virtuose. Ni son professeur ni ses parents ne [savè] @savaient à quel point ces commentaires se révéleraient justes. À dix-sept ans, selon les dires de spécialistes qui [survèj] @surveillent ses progrès, il semblerait qu’il soit en voie de devenir un musicien [aimérit] @émérite .<br>"
    },
    {
        id : 13,
        titre : "Compléter les phrases",
        texte : "Quoique ce travail leur demande beaucoup de temps et d’efforts, ils s’arrêtent quelquefois pour se raconter [tel] @telle anecdote survenue durant la semaine ou encore [leur] @leurs démêlés avec [leur] @leur professeur de français, celui-là même qui leur conseille de ne pas s’asseoir ensemble en classe. Mais après ces quelques minutes de répit, [tou] @tout rentre dans l’ordre. Tout à leur tâche, [tou] @tout fatigués qu’ils soient, ils ne s’arrêteront que lorsqu’ils auront [tou] @tout compris. Quelque tentés qu’ils soient de s’amuser, ils demeurent [tou] @tout à leur affaire. <br>"
    },
    {
        id : 14,
        titre : "Écrire les couleurs - difficile",
        texte : "Des robes [vert] @vertes <br> Des robes [turqoise] @turquoise <br> Des robes [maron] @marron <br>Des robes jaune [fonsé] @foncé <br>Des robes bleu [clair] @clair <br>Des robes [jaune] @jaunes "
    }
    ,
    {
        id : 15,
        titre : "Retour en enfance - une souris verte",
        texte : "Une souris verte<br>Qui courait dans l'herbe<br>Je l'attrape par la @queue <br>Je la montre à ces @messieurs <br>Ces messieurs me disent<br>Trempez-la dans l'huile<br>Trempez-la dans l'eau<br>Ça fera un @escargot <br>Tout chaud<br>Je la mets dans un @tiroir <br>Elle me dit qu'il fait trop noir </br>Je la mets dans mon @chapeau <br>Elle me dit qu'il fait trop chaud<br>"
    },
    {
        id : 18,
        titre : "La féminisation des métiers",
        extrait : "La question reste ouverte pour de nombreux cas dans les dictionnaires (auteur, écrivain...).",
        texte : "Un fermier, une @fermière <br> Un fonctionnaire, une @fonctionnaire <br>Un médecin, une @médecin <br>Un sergent, une @sergente <br>Un sculpteur, une @sculptrice <br>Un metteur en scène, une @metteuse en scène <br>Un abbé, une @abbesse <br>Une sagefemme (rectifications orthographiques de 1990), un @sagefemme <br>Un valet de chambre, une @femme de chambre <br>Un ingénieur, une @ingénieure <br>"
    },
    {
        id : 19,
        titre : "Quel(s) / quelle(s) – qu'elle(s)",
        extrait : "Complétez [quel] par la bonne orthographe. Revoir le <a rel='noopener noreferrer' target='_blank' href='https://www.ortholud.com/lecon-quel-quels-quelle-quelles-qu-elle-qu-elles.html/lang=fr'>cours</a>",
        texte : "Ah, [quel] @quelle belle pomme !<br>Il fait toujours froid, [quel] @quelles sont vos prévisions pour la semaine ?<br>Voici des fleurs [quel] @qu'elle a cueillies pour toi. <br>Ils refusent de tuer un animal, [quel] @quel qu’il soit<br>Ah, [quel] @quelle tristesse ! <br>À [quel] @quels événements fait-il allusion <br>Pendant [quel] @quelles discussions avez-vous abordé ce sujet ? <br>À [quel] @quel moment partirez-vous ?<br>Qu’est-ce [quel] @qu'elles ont tant à faire ?<br>"
    },
    {
        id : 20,
        titre : "Savoir écrire des, dès, dés, sur, sûr et sûre",
        extrait : "Complétez [des] et [sur] avec la bonne orthographe.",
        texte : "J'espère que vous l'examinerez [des] @dès que vous le pourrez.<br>Vous pouvez apprendre à jouer aux [des] @dés mexicains. <br>Cela doit être fait [des] @dès que la liste sera mise à disposition. <br>Avez-vous [des] @des enfants ? <br>Aux commandes d'un bateau, il est [sur] @sûr de lui. <br>Location de voiture électriques [des] @dès 50 euros par jour. <br> Elle est [sur] @sûre d’elle.<br> Quels sont les différents jeux de [des] @dés ? <br> Laissez trois hommes ensemble après le dîner, vous pouvez être [sur] @sûr que la conversation tombera sur les femmes. (Flaubert) <br> Il danse [sur] @sur le mur de Berlin. <br>"
    },
    // {
    //     id : 25,
    //     titre : "Majuscule ou pas",
    //     extrait : "Il suffit simplement de réécrire le mot entre crochets avec ou sans majuscule. ",
    //     texte : "Le [président] @président de la [république] @République parle au nom de la France.<br>Il est nommé [premier] @Premier ministre. <br>Elle a de la chance ; [il] @il s'est mis à neiger pour les sports d'hiver.<br>Il apprend le [chinois] @chinois . <br>Le palais Bourbon est le nom communément donné au bâtiment qui abrite l'Assemblée [nationale] @nationale française. <br>La [tour] @tour Eiffel est une tour de 324 mètres de hauteur. <br> Les [parisiens] @Parisiens sont-ils des privilégiés ?<br> Le [moyen] @Moyen Âge est la période de l'histoire européenne qui s'étend de la fin de l'Empire romain au début de la Renaissance.<br> Le [préfet] @préfet est la représentation de l’État au niveau d'un département.  <br>"
    // },
    // {
    //     id : 21,
    //     titre : "Masculin ou féminin",
    //     extrait : "Ajouter un ou une (sans majuscule) devant chaque mot",
    //     texte : "(un/une) @un haltère<br> (un/une) @un pétale<br> (un/une) @un équinoxe<br> (un/une) @un enseigne (l'officier de la marine de guerre)<br> (un/une) @une anagramme<br> (un/une) @une oasis<br> (un/une) @un abîme<br> (un/une) @une apostrophe<br> (un/une) @une stalactite<br> (un/une) @un tentacule<br>"

    // },
    // {
    //     id : 22,
    //     titre : " Écrire le contraire des mots ou des adjectifs ci-dessous avec des préfixes",
    //     extrait : "Exemple : lisible - illisible",
    //     texte : "responsable @irresponsable <br> hydraté  @déshydraté <br>moral @immoral <br>défini @indéfini <br>populaire @impopulaire <br>mortel @immortel <br>lettré @illettré <br>observable @inobservable <br>accordé @désaccordé <br>hospitalier @inhospitalier <br>"

    // },
    // },
    // {
    //     id : 23,
    //     titre : "Les abréviations",
    //     extrait : "Écrire la forme abrégée du mot entre parenthèses. Certaines abréviations nécessitent normalement des exposants (madame) mais cela n’est pas possible ici. On se contentera de l’écrire simplement sur la même ligne.",
    //     texte : "(Monsieur) @M. Dupont <br>(Messieurs) @MM. Corchan et Lucas <br>(Madame) @Mme N'Dyaye <br>5 (minutes) @min <br>4 (kilomètres) @km <br>Les enseignants du (second) @2d degré <br>Le (premier) @1er prix <br>Le (troisième) @3e prix <br>L'expression latine « exempli gratia » est principalement utilisée par les anglophones pour illustrer son point de vue à l'aide d'un exemple, sa forme abrégée sans espace est @e.g. <br>La classe de (seconde) @2de <br>"

    // },
    
   
    
];
export default  DonneePhraseTrou
