import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import Ad from '../commun/adSense';

import { Helmet } from 'react-helmet';
import border from '../../images/border.png';


export default class Grammaire extends Component
{

  constructor()
  {
    super();
 
  }
 
    render()
    {
        return(
        <div>
           <Helmet>
           <title>Les tests d'orthographe : la grammaire </title>
          <meta name="description" content="Des exercices et des tests d'orthographe gratuits en ligne pour tous les niveaux et tous les âges (enfant ou adulte). Corrigez les fautes dans un texte ou complétez les mots manquants." />
        </Helmet>
        <div className="titre centre">Tests d'orthographe et de grammaire</div>
      <div className='centre'>evalquiz : le site numéro 1 du divertissement intelligent</div>
      <h2 className="titre centre">L'orthographe et la grammaire</h2>
      <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Les tests rapides</h1>
            <p>Des exercices simples sur l'orthographe où il n'y a que 2 choix possibles.</p>
          </Col>
        <Col md={10}>
        <Card title="Tests avec deux choix">

            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1060">Vérifier orthographe des mots - 1</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1062">Vérifier orthographe des mots - 2</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1063">Vérifier orthographe des mots - 3</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1064">Vérifier orthographe des mots - 4</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1065">Vérifier orthographe des mots - 5</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1075">10 mots difficiles à écrire - 6</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1282">10 mots très difficiles à écrire</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1283">Les mots avec la réforme de l'orthographe de 1990</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1284">Orthographe des mots de plus de 12 lettres</a></div>
        
           <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1189">La phrase est-elle sans-faute ? 1</a></div>
           <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1192">La phrase est-elle sans-faute ? 2</a></div>
           <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1193">La phrase est-elle sans-faute ? 3</a></div>
           <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1287">La phrase est-elle sans-faute ? 4</a></div>
           <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1288">La phrase est-elle sans-faute ? 5</a></div>

            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1185">Masculin ou féminin 1</a></div>
            <div class="margeLien"><a href="https://evalquiz.com/motcorrect/jeumotcorrect/jeu?idTest=1186">Masculin ou féminin 2</a></div>
             
</Card>
        </Col>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
      <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>QCM de grammaire</h1>
            <p>Des exercices simples de grammaire où vous devez choisir la bonne proposition parmi plusieurs.</p>
          </Col>
        <Col md={10}>
          <SousMenu type="qcm" titre="Trouvez la bonne réponse"></SousMenu>
        </Col>
        </Row>
      <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Phrases à compléter</h1>
            <p>Un autre test d'orthographe où il s'agit de compléter une phrase en ajoutant les mots manquants.</p>
            <p>Pour valider la correction il suffit d'appuyer sur Entrée.</p>
            <p>Les mots manquants sont quelquefois écrits en phonétique française.</p>
          </Col>
        <Col md={10}>
          <SousMenu type="phrasetrou" titre="Complétez les mots dans la phrase"></SousMenu>
        </Col>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
     
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Texte à corriger</h1>
            <p>Vous devez retrouver les mots contenant des fautes d'orthographe puis ensuite apporter une correction.Le nombre de fautes dans le texte est indiqué.
               Les exercices sont classés par ordre de difficulté.
            </p>

          </Col>
        <Col md={10}>
       <SousMenu type="phraseerreur" titre="Trouvez les erreurs dans le texte"></SousMenu>
        </Col>
        </Row>
        <Ad></Ad>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Tester sa mémoire et améliorer son orthographe</h1>
            <p>Regardez pendant quelques secondes une image contenant des mots. </p>
            <p>Mémorisez les mots présents.</p>
            <p>Répondez aux questions qui vous sont posées.</p>
            <p>Si vous devez écrire plusieurs mots ceux-ci doivent être séparés par un retour à la ligne.</p>
            
          </Col>
        <Col md={10}>
       <SousMenu type="memoire" titre="Mémoriser des mots"></SousMenu>
        </Col>
        </Row>
  
       
        </div>)
    }

}
