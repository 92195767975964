
import React from 'react';
import {ScrollRestoration, createBrowserRouter, Outlet } from "react-router-dom";
import Presentation from './Presentation';


//import PhraseTrou from '../../jeux/phraseTrou/PhraseTrou';
//import PhraseErreur from '../../jeux/phraseErreur/PhraseErreur';
//import VerbeConjugaison from '../../jeux/VerbeConjugaison/VerbeConjugaison';
//import OrthographeMot from '../../jeux/OrthographeMot/OrthographeMot';
//import MemoireJeu from '../../jeux/memoire/MemoireJeu';
//import MotDefinition from '../../jeux/motDefinition/MotDefinition';
//import ListeMots from '../../jeux/listeMots/ListeMots';
//import DebutEtape from '../../jeux/concours/DebutEtape';
//import MasterJeu from '../../jeux/masterMot/MasterJeu';
//import JeuDesordre from '../../jeux/desordre/JeuDesordre';
//import Boogle from '../../jeux/boogle/JeuBoogle';
//import JeuQcmMelange from '../../jeux/culture/JeuQcmMelange';
//import JeuQcm from '../../jeux/qcm/jeuQcm';

import Dictee from './Dictee';
import Grammaire from './Grammaire';
import JeuxMots from './JeuxMots';
import Conjugaison from './Conjugaison';
import { Menu} from 'antd';
import { itemsMenu } from './menu';
import PageFaute from '../commun/PageFaute';


const JeuxDictee = React.lazy(() => import('../../jeux/dictee/JeuxDictee'));
const PhraseTrou = React.lazy(() => import('../../jeux/phraseTrou/PhraseTrou'));
const PhraseErreur = React.lazy(() => import('../../jeux/phraseErreur/PhraseErreur'));
const VerbeConjugaison = React.lazy(() => import('../../jeux/VerbeConjugaison/VerbeConjugaison'));
const OrthographeMot = React.lazy(() => import('../../jeux/OrthographeMot/OrthographeMot'));
const MemoireJeu = React.lazy(() => import('../../jeux/memoire/MemoireJeu'));
const MotDefinition = React.lazy(() => import('../../jeux/motDefinition/MotDefinition'));
const ListeMots = React.lazy(() => import( '../../jeux/listeMots/ListeMots'));
const DebutEtape = React.lazy(() => import('../../jeux/concours/DebutEtape'));
const MasterJeu = React.lazy(() => import('../../jeux/masterMot/MasterJeu'));
const JeuDesordre = React.lazy(() => import( '../../jeux/desordre/JeuDesordre'));
const Boogle = React.lazy(() => import('../../jeux/boogle/JeuBoogle'));
const JeuQcmMelange = React.lazy(() => import('../../jeux/culture/JeuQcmMelange'));
const JeuQcm = React.lazy(() => import('../../jeux/qcm/jeuQcm'));





const router = createBrowserRouter([
    {
      path: '/',
      element: <div><ScrollRestoration></ScrollRestoration>
      <div className='menuHaut'><Menu items={itemsMenu} mode="horizontal"></Menu></div>
      <div className='margeEcran'><Outlet></Outlet></div></div>,
      errorElement : <PageFaute></PageFaute>,
      children: [
       
          {
            index : true,
            element: <Presentation />,
          },
          {
            path :"jeu",
            element: <Presentation />,
          },
          {
            path: 'dictees',
            element: <Dictee/>,
          },
          {
            path: 'conjugaison',
            element: <Conjugaison/>,
          },
          {
            path: 'grammaire-orthographe',
            element: <Grammaire/>,
          },
          {
            path: 'jeux-mots',
            element: <JeuxMots/>,
          },
          {
            path: 'dictee/:id',
            element: <JeuxDictee/>,
          },
          {
            path: 'phrasetrou/:id',
            element: <PhraseTrou/>,
            
          },
          {
            path: 'phraseerreur/:id',
            element: <PhraseErreur/>,
            
          },
          {
            path: 'verbeconjugaison/:id',
            element: <VerbeConjugaison/>,
            
          },
          {
            path: 'orthographemot/:id',
            element: <OrthographeMot/>,
            
          },
          {
            path: 'memoire/:id',
            element: <MemoireJeu/>,
            
          },
          {
            path: 'motdefinition/:id',
            element: <MotDefinition/>,
            
          },
          {
            path: 'listemots/:id',
            element: <ListeMots/>,
            
          },
          {
            path: 'mastermind-mot/:id',
            element: <MasterJeu/>,
            
          },
          {
            path: 'desordre/:id',
            element: <JeuDesordre/>,
            
          },
          {
            path: 'qcmmelange/:id',
            element: <JeuQcmMelange/>,
            
          },
          {
            path: 'boogle/:id',
            element: <Boogle/>,
            
          },
          {
            path: 'defiorthographe/:no',
            element: <DebutEtape/>,
            
          },
          {
            path: 'qcm/:id',
            element: <JeuQcm/>,
            
          },
      
      
      ], 
    },
   

  ]);
  


export {router}