import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Ad from '../commun/adSense';
import border from '../../images/border.png';
import image1 from '../../images/pyramide.jpg';
import { Helmet } from 'react-helmet';


export default class JeuxMots extends Component
{

  constructor()
  {
    super();
  
  }
 
    render()
    {
        return(
        <div>
           <Helmet>
           <title>Les tests d'orthographe : jouer avec les mots </title>
          <meta name="description" content="Des jeux avec les mots et l'orthographe pour se divertir intelligemment. Vous pourrez jouer à de grands classiques des jeux de lettres et de mots comme des anagrammes, le jeu Motus, Wordle, Boogle..." />
        </Helmet>
        <div className="titre centre">Les mots et l'orthographe</div>
      <div className='centre'>evalquiz : le site numéro 1 du divertissement intelligent</div>
      <h2 className="titre centre">Jeux de lettres et de mots</h2>
      <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
      <div className="centre"><img className="resp" src={image1} alt="jeu pyramide"></img></div>
        <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>La pyramide des mots</h1>
            <p>Un jeu simple mais très amusant dans lequel vous devez retrouver un mot à partir de sa définition.</p>
            <p>Chaque nouveau mot trouvé permet de découvrir de nouvelles lettres dans les autres mots.</p>
            <p>Ce jeu est inspiré par d'autres jeux de mots comme Slam, les mots croisés... Le but est toujours d'apprendre en s'amusant !</p>
            <p>Les images libres de droit proviennent du site <a href="https://www.pexels.com/">pexels.com</a>.</p>
            
          </Col>
        <Col md={10}>
          <SousMenu type='listemots' titre='La pyramide des mots'></SousMenu>
        </Col>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="grandEspaceHaut">
          <Col md={8}>
            <h1>Le jeu des 6 mots</h1>
            <p>Un jeu de lettres où vous devez trouver 6 mots à partir de 7 lettres qui n'est pas sans rappeler le jeu du Boogle de notre enfance, les mots doivent obligatoirement contenir la lettre centrale.</p>
            <p>Tous les mots sont acceptés s'ils sont répertoriés dans notre dictionnaire, vous avez aussi le droit d'utiliser des verbes conjugués. </p>
           <p>Un jeu de lettres amusant et rapidement addictif.</p>
          
            

          </Col>
        <Col md={10}>
        <SousMenu type='boogle' titre='Le jeu des 6 mots'></SousMenu>
        </Col>
        </Row>
        <Ad></Ad>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="grandEspaceHaut">
          <Col md={8}>
            <h1>La culture générale mélangée</h1>
            <p>Dans ce jeu de culture générale vous devez répondre aux questions en remettant les réponses dans l'ordre. </p>
            <p>Un jeu qui s'intéresse surtout à l'orthographe des noms propres, il n'est pas toujours facile de savoir écrire les noms russes ou allemands.
           </p>
            

          </Col>
        <Col md={10}>
        <SousMenu type='qcmmelange' titre='Culture générale'></SousMenu>
        </Col>
        </Row>
    
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="grandEspaceHaut">
          <Col md={8}>
            <h1>Lettres en désordre</h1>
            <p>Dans ce jeu vous devez retrouver des mots parmi des lettres en désordre.</p>
            <p>Tous les mots sont acceptés s'ils sont répertoriés dans notre dictionnaire, vous avez aussi le droit d'utiliser des verbes conjugués. 
           </p>
            

          </Col>
        <Col md={10}>
        <SousMenu type='desordre' titre='Mots en désordre'></SousMenu>
        </Col>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="grandEspaceHaut">
          <Col md={8}>
            <h1>Motus et Wordle revisités</h1>
            <p>Un jeu qui n'est pas sans rappeler le jeu de France 2 "Motus" pour les plus anciens ou l'application Wordle pour les plus jeunes.</p>
            <p>Dans ce petit jeu vous devez retrouver un mot choisi par l'ordinateur, vous avez le droit de proposer 6 mots en tout. 
            À chaque fois l'ordinateur vous indique le nombre de lettres bien placées et le nombre de lettres mal placées.</p>
            

          </Col>
        <Col md={10}>
        <SousMenu type='mastermind-mot' titre='Motus - Wordle'></SousMenu>
        </Col>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Le mot à partir de sa définition</h1>
            <p>Des définitions issues du dictionnaire sont affichées, à vous de retrouver le plus rapidement possible le mot correspondant à la définition. 
            Pour vous aider une lettre du mot est affichée toutes les 3 secondes. Le but est de retrouver le mot le plus rapidement possible. Pour chaque test 5 définitions sont affichées. </p>
            <p>Ce test permet d'améliorer son vocabulaire, son orthographe et sa capacité de concentration.</p>
          </Col>
        <Col md={10}>
        <SousMenu titre='Mot et sa définition' type='motdefinition'></SousMenu>
        </Col>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row>
        <Col>
            <h1>Le challenge des mots </h1>
          <p>Vous aimez les jeux de lettres et de mots comme le Boogle, le Scrabble, Motus, les anagrammes ? Alors vous devez essayer notre nouveau <a href="https://concours.evalquiz.com">challenge des lettres et des mots</a>.</p>
        <p>Les jeux sont ouvert à tous (junior ou senior) et ils ne nécessitent pas d'inscription.</p>
        <p>Arrivez-vous à réussir les 32 étapes de notre challenge ?</p>

                </Col>
  </Row>
  <Ad></Ad>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
   
        </div>)
    }

}
