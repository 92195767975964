import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Ad from '../commun/adSense';

import { Helmet } from 'react-helmet';
import border from '../../images/border.png';
import Tableau from '../commun/Tableau';


export default class Conjugaison extends Component
{

  constructor()
  {
    super();

  }
 
    render()
    {
        return(
        <div>
           <Helmet>
           <title>Les tests d'orthographe : la conjugaison</title>
          <meta name="description" content="Des tests sur la conjugaison française en ligne pour tous les niveaux (facile ou difficile) et tous les âges (enfant ou adulte). " />
        </Helmet>
        <div className="titre centre">Tests d'orthographe et de grammaire</div>
      <div className='centre'>evalquiz : le site numéro 1 du divertissement intelligent</div>
      <h2 className="titre centre">La conjugaison</h2>
      <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
      <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Les conjugaisons</h1>
            <p>Dans ces tests vous devez conjuguer un verbe.</p>
            <p>Vous pouvez réviser vos conjugaisons sur ce <a href="https://leconjugueur.lefigaro.fr/regle" rel="noopener">site</a>.</p>
            <p>Si vous réalisez un sans-faute, votre prénom sera inscrit au  tableau d'honneur de la conjugaison.</p>
         
      </Col>
        <Col md={10}>
        <SousMenu type='verbeconjugaison' titre='Révisez vos conjugaisons'></SousMenu>
        </Col>
        </Row>
        <Ad></Ad>
        <Tableau type="verbeconjugaison"></Tableau>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
    
        </div>)
    }

}
