const donneesQcm =
    [
        {
            id: 1,
            titre: "Conjugaison des verbes - très facile",
            niveau: 1,
            info: [
                {
                    phrase: "Le chien @aboie@ tous les jours.",
                    reponses: ["aboient", "aboie", "aboies"]
                },
                {
                    phrase: "Vous @allez@ à la fête foraine avec vos parents.",
                    reponses: ["allé", "aller", "allez"]
                },
                {
                    phrase: "Mon père @est@ en colère.",
                    reponses: ["est", "et", "es"]
                },
                {
                    phrase: "Tu @dois@ ranger ta chambre.",
                    reponses: ["doit", "dois", "bois"]
                }, 
                {
                    phrase: "Le marchand @vend@ des glaces.",
                    reponses: ["vends", "vent", "vend"]
                },
                {
                    phrase:"J'@ai@ un appareil dentaire.",
                    reponses: ["est", "ai", "ais"]
                }

                
            ]

        },
        {
            id: 2,
            titre: "L'impératif présent - facile",
            niveau: 1,
            info: [
                {
                    phrase: "@Mets@ ta main devant ta bouche !",
                    reponses: ["Met", "Mets"]
                },
                {
                    phrase: "Ne @mens@ pas tout le temps.",
                    reponses: ["men", "ment", "mens"]
                },
                {
                    phrase: "Ne @pointe@ pas du doigt les gens.",
                    reponses: ["pointe", "pointes"]
                },
                {
                    phrase: "Ne @regarde@ pas trop ton smartphone.",
                    reponses: ["regarde", "regardes"]
                }, 
                {
                    phrase: "@Couche@-toi !",
                    reponses: ["Couche", "Couches"]
                },
                {
                    phrase:"@Prends@ l'argent !",
                    reponses: ["Prend", "Prends"]
                }

                
            ]

        },
        {
            id: 3,
            titre: "Accord du verbe - moyen",
            aide : "Pour ceux qui veulent plus d'explications - <a href='https://www.cordial.fr/grammaire/manuels/ACCO_PP_1.htm'>Accord du participe passé : être et avoir</a>",
            niveau: 1,
            info: [
                {
                    phrase: "Les fleurs, Marc les a @arrosées@ ce matin.",
                    reponses: ["arrosé", "arrosées", "arrosés"]
                },
               
                {
                    phrase: "La peinture a complètement @séché@.",
                    reponses: ["séché", "séchée"]
                },
                {
                    phrase: "La violence scolaire a toujours @existé@.",
                    reponses: ["existé", "existée"]
                }, 
                {
                    phrase: "Le pull et la chemise que Martine a @portés@ hier sont sales.",
                    reponses: ["portés", "porté", "portées"]
                },
                {
                    phrase: "Les athlètes féminines sont bien @entraînées@.",
                    reponses: ["entraînés", "entraînées", "entraîné"]
                },
                {
                    phrase:"Quels dessins ont-ils @faits@ ?",
                    reponses: ["fait", "faits"]
                }

                
            ]

        }
   
    ]
export default donneesQcm;