const DonneeOrthographeMot = [
    {
        id: 1,
        titre: "Des mots simples niveau CM2",
        test : [
            {
                fichier: 'mot11.mp3',
                mot: 'attentivement'
            },
            {
                fichier: 'mot12.mp3',
                mot: 'décourager'
            },
            {
                fichier: 'mot13.mp3',
                mot: 'dernièrement'
            },
            {
                fichier: 'mot14.mp3',
                mot: 'éléphant'
            },
            {
                fichier: 'mot15.mp3',
                mot: 'appartement'
            }
        ]
    },
    {
        id: 2,
        titre: "Orthographe des mots niveau CM2",
        test : [
            {
                fichier: 'mot21.mp3',
                mot: 'cimetière'
            },
            {
                fichier: 'mot22.mp3',
                mot: 'enseignement'
            },
            {
                fichier: 'mot23.mp3',
                mot: 'fougère'
            },
            {
                fichier: 'mot24.mp3',
                mot: 'stupéfaction'
            },
            {
                fichier: 'mot25.mp3',
                mot: 'conférence'
            }
        ]
    },
    {
        id: 3,
        titre: "Toujours des mots simples niveau fin de primaire",
        test : [
            {
                fichier: 'mot31.mp3',
                mot: 'giboulée'
            },
            {
                fichier: 'mot32.mp3',
                mot: 'malheureusement'
            },
            {
                fichier: 'mot33.mp3',
                mot: 'prodigieux'
            },
            {
                fichier: 'mot34.mp3',
                mot: 'épouser'
            },
            {
                fichier: 'mot35.mp3',
                mot: 'orphelin'
            }
        ]
    },
    {
        id: 4,
        titre: "Des mots plus difficiles à écrire niveau fin de collège",
        test : [
            {
                fichier: 'mot41.mp3',
                mot: 'suffisamment'
            },
            {
                fichier: 'mot42.mp3',
                mot: 'raccommoder'
            },
            {
                fichier: 'mot43.mp3',
                mot: 'raccourcir'
            },
            {
                fichier: 'mot44.mp3',
                mot: 'carrousel'
            },
            {
                fichier: 'mot45.mp3',
                mot: 'remords'
            }
        ]
    },
    {
        id: 5,
        titre: "Orthographe des mots niveau fin de collège",
        test : [
            {
                fichier: 'mot51.mp3',
                mot: 'sympathie'
            },
            {
                fichier: 'mot52.mp3',
                mot: 'dégât'
            },
            {
                fichier: 'mot53.mp3',
                mot: 'embarras'
            },
            {
                fichier: 'mot54.mp3',
                mot: 'enthousiasme'
            },
            {
                fichier: 'mot55.mp3',
                mot: 'jacinthe'
            }
        ]
    },
    {
        id: 6,
        titre: "Test orthographe des mots niveau lycée",
        test : [
            {
                fichier: 'mot61.mp3',
                mot: 'métaphore'
            },
            {
                fichier: 'mot62.mp3',
                mot: 'conscience'
            },
            {
                fichier: 'mot63.mp3',
                mot: 'inconsciemment'
            },
            {
                fichier: 'mot64.mp3',
                mot: 'dilemme'
            },
            {
                fichier: 'mot65.mp3',
                mot: 'cédille'
            }
        ]
    },
    {
        id: 7,
        titre: "Mots difficiles à orthographier niveau lycée",
        test : [
            {
                fichier: 'mot71.mp3',
                mot: 'enveloppe'
            },
            {
                fichier: 'mot72.mp3',
                mot: 'labyrinthe'
            },
            {
                fichier: 'mot73.mp3',
                mot: 'décès'
            },
            {
                fichier: 'mot74.mp3',
                mot: 'insouciance'
            },
            {
                fichier: 'mot75.mp3',
                mot: 'onomatopée'
            }
        ]
    },
    {
        id: 8,
        titre: "Orthographe des mots très difficiles",
        test : [
            {
                fichier: 'mot81.mp3',
                mot: 'thaumaturge'
            },
            {
                fichier: 'mot82.mp3',
                mot: 'thuriféraire'
            },
            {
                fichier: 'mot83.mp3',
                mot: 'obédience'
            },
            {
                fichier: 'mot84.mp3',
                mot: 'méphitique'
            },
            {
                fichier: 'mot85.mp3',
                mot: 'histrion'
            }
        ]
    },
    {
        id: 9,
        titre: "Test orthographe des mots très difficiles",
        test : [
            {
                fichier: 'mot91.mp3',
                mot: 'hédoniste'
            },
            {
                fichier: 'mot92.mp3',
                mot: 'épigramme'
            },
            {
                fichier: 'mot93.mp3',
                mot: 'dithyrambique'
            },
            {
                fichier: 'mot94.mp3',
                mot: 'déshérence'
            },
            {
                fichier: 'mot95.mp3',
                mot: 'apocryphe'
            }
        ]
    },
    {
        id: 10,
        titre: "Écrire les mots avec des accents sans faute",
        test : [
            {
                fichier: 'mot101.mp3',
                mot: 'belvédère'
            },
            {
                fichier: 'mot102.mp3',
                mot: 'célèbre'
            },
            {
                fichier: 'mot103.mp3',
                mot: 'éphémère'
            },
            {
                fichier: 'mot104.mp3',
                mot: 'phénomène'
            },
            {
                fichier: 'mot105.mp3',
                mot: 'mystère'
            }
        ]
    },
    {
        id: 11,
        titre: "Des mots difficiles à prononcer et à écrire 1",
        test : [
            {
                fichier: 'mot111.mp3',
                mot: 'puzzle'
            },
            {
                fichier: 'mot112.mp3',
                mot: 'reblochon'
            },
            {
                fichier: 'mot113.mp3',
                mot: 'mnémotechnique'
            },
            {
                fichier: 'mot114.mp3',
                mot: 'maelström'
            },
            {
                fichier: 'mot115.mp3',
                mot: 'cerf'
            }
        ]
    },
    {
        id: 12,
        titre: "Des mots difficiles à prononcer et à écrire 2",
        test : [
            {
                fichier: 'mot121.mp3',
                mot: 'broc'
            },
            {
                fichier: 'mot122.mp3',
                mot: 'rébellion'
            },
            {
                fichier: 'mot123.mp3',
                mot: 'oignon'
            },
            {
                fichier: 'mot124.mp3',
                mot: 'ananas'
            },
            {
                fichier: 'mot125.mp3',
                mot: 'exsangue'
            }
        ]
    },
    
    {
        id: 13,
        titre: "Écrire des mots simples avec 2 accents ou plus",
        test : [
            {
                fichier: 'mot131.mp3',
                mot: 'écuyère'
            },
            {
                fichier: 'mot132.mp3',
                mot: 'théorème'
            },
            {
                fichier: 'mot133.mp3',
                mot: 'délétère'
            },
            {
                fichier: 'mot134.mp3',
                mot: 'télésiège'
            },
            {
                fichier: 'mot135.mp3',
                mot: 'pépinière'
            }
        ]
    },
    {
        id: 14,
        titre: "Écrire des mots de plus de 12 lettres",
        test : [
            {
                fichier: 'mot141.mp3',
                mot: 'conférencière'
            },
            {
                fichier: 'mot142.mp3',
                mot: 'parallélépipède'
            },
            {
                fichier: 'mot143.mp3',
                mot: 'irrégulièrement'
            },
            {
                fichier: 'mot144.mp3',
                mot: 'arbalétrière'
            },
            {
                fichier: 'mot145.mp3',
                mot: 'écartèlement'
            }
        ]
    },
    {
        id: 15,
        titre: "L'accord des adjectifs dans une phrase 1 - facile",
        test : [
            {
                fichier: 'phrase1.mp3',
                mot: 'énervés',
                phrase : "Jean et Tarik sont $."
            },
            {
                fichier: 'phrase2.mp3',
                mot: 'légères',
                phrase : "Les soupières sont aussi très $."
            },
            {
                fichier: 'phrase3.mp3',
                mot: 'sympathique',
                phrase : "La concierge est $."
            },
            {
                fichier: 'phrase4.mp3',
                mot: "paresseuses",
                phrase : "Elles sont très $."
                
            },
            {
                fichier: 'phrase5.mp3',
                mot: 'bon',
                phrase : "Ces roses sentent $."
            }
        ]
    },
    {
        id: 16,
        titre: "L'accord des adjectifs dans une phrase 2 - intermédiaire",
        test : [
            {
                fichier: 'phrase10.mp3',
                mot: 'plastifiée',
                phrase : "Une carte des desserts $"
            },
            {
                fichier: 'phrase11.mp3',
                mot: 'vocales',
                phrase : "Un examen des cordes $ douloureux"
            },
            {
                fichier: 'phrase12.mp3',
                mot: 'grecque',
                phrase : "Un livre de littérature $"
            },
            {
                fichier: 'phrase13.mp3',
                mot: "réélue",
                phrase : "Une candidate aux élections municipales $"
                
            },
            {
                fichier: 'phrase14.mp3',
                mot: 'lues',
                phrase : "Sur toutes les pages $. J’écris ton nom."
            }
        ]
    },
    // {
    //     id: 17,
    //     titre: "Concours (nouveau)",
    //     test : [
    //         {
    //             fichier: 'phrase60.mp3',
    //             mot: 'arrêtées',
    //             phrase : "Elles se sont $ pour se reposer."
    //         },
    //         {
    //             fichier: 'phrase61.mp3',
    //             mot: 'montrerai',
    //             phrase : "Au musée, je vous $ plusieurs tableaux."
    //         },
    //         {
    //             fichier: 'phrase62.mp3',
    //             mot: 'viendras',
    //             phrase : "Est-ce que tu $ à la piscine avec moi ? "
    //         },
    //         {
    //             fichier: 'phrase63.mp3',
    //             mot: "angoissés",
    //             phrase : "Les patineuses et leur entraîneur, à la veille de la compétition, sont $."
                
    //         },
    //         {
    //             fichier: 'phrase64.mp3',
    //             mot: 'bleues',
    //             phrase : "Un troupeau de brebis égarées avançait paisiblement au milieu des fleurs $."
    //         }
    //     ]
    // },
    // {
    //     id: 18,
    //     titre: "Concours 2 - facile",
    //     test : [
    //         {
    //             fichier: 'phrase70.mp3',
    //             mot: 'travers',
    //             phrase : "Le roi Dagobert faisait tout de $. Il mettait sa culotte à l’envers."
    //         },
    //         {
    //             fichier: 'phrase71.mp3',
    //             mot: 'asphyxiant',
    //             phrase : "L’atmosphère suffocante les oblige à respirer de l’air $."
    //         },
    //         {
    //             fichier: 'phrase72.mp3',
    //             mot: 'leurs pieds',
    //             phrase : "Il leur faut reculer car le sol vibre sous $."
    //         },
    //         {
    //             fichier: 'phrase73.mp3',
    //             mot: "entrée",
    //             phrase : "Papa a ouvert et une femme deux fois plus grande que lui est $."
                
    //         },
    //         {
    //             fichier: 'phrase74.mp3',
    //             mot: 'développée',
    //             phrase : "En France, l’agriculture biologique s’est considérablement $."
    //         }
    //     ]
    // }
]

export default DonneeOrthographeMot;
