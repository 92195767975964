
const DonneesListeMots =
    [
        {
            id: 1,
            titre: 'Pyramide de mots Niveau 1.1',
            temps : 80,
            liste: [
                {
                    definition: "Première lettre de l’alphabet",
                    mot: "a"
                },
                {
                    definition: "On en trouve beaucoup le long de la Loire.",
                    mot: "châteaux"
                },
                {
                    definition: "un fanal des …",
                    mot: "fanaux"
                },
                {
                    definition: "Arc monté sur un fût et lançant des projectiles appelés « carreaux »",
                    mot: "arbalète"
                },
                {
                    definition: "Satellite naturel de la Terre ",
                    mot: "lune"
                }

            ]
        },
        {
            id: 2,
            titre: 'Pyramide de mots Niveau 1.2',
            temps : 100,
            liste: [
                {
                    definition: "Dans avion mais pas dans nova",
                    mot: "i"
                },
                {
                    definition: "La maman en raconte une à son enfant tous les soirs avant de se coucher.",
                    mot: "histoire"
                },
                {
                    definition: "Un œil des  ...",
                    mot: "yeux"
                },
                {
                    definition: "Gymnastique douce d'origine indienne",
                    mot: "yoga"
                },
                {
                    definition: "Roman de Zola sur l'exploitation d'une mine de charbon dans le Nord",
                    mot: "germinal"
                }

            ]
        },
        {
            id: 3,
            titre: 'Pyramide de mots Niveau 2.1',
            temps : 120,
            liste: [
                {
                    definition: "Dernière lettre de la capitale des États-Unis",
                    mot: "n"
                },
                {
                    definition: "Tombeaux des rois et des reines en Égypte ",
                    mot: "pyramides"
                },
                {
                    definition: "Crustacé protégé par une carapace et doté de grandes pinces que l’on peut déguster au restaurant",
                    mot: "homard"
                },
                {
                    definition: "Le peintre de Guernica",
                    mot: "picasso"
                },
                {
                    definition: "Personne chargée de l’organisation de la cave et du service des vins dans un restaurant",
                    mot: "sommelier"
                },
                {
                    definition: "Système politique dans lequel un dirigeant favorise les membres de sa famille en leur octroyant des postes alors qu'ils n'ont pas les compétences.",
                    mot: "népotisme"
                }

            ]
        },
        {
            id: 5,
            titre: 'Pyramide de mots Niveau 2.2',
            temps : 100,
            liste: [
                {
                    definition: "L'arbre de Noël",
                    mot: "sapin"
                },
                {
                    definition: "Quelle lettre vaut 10 points au Scrabble dans la capitale du Népal ? ",
                    mot: "k"
                },
                {
                    definition: "Cette créatrice de mode lance son premier parfum en 1925 qui porte le numéro 5",
                    mot: "chanel"
                },
                {
                    definition: "Jeune sanglier de moins de six mois d'âge",
                    mot: "marcassin"
                },
                {
                    definition: "La couleur de la passion",
                    mot: "rouge"
                },
                {
                    definition: "La capitale du Chili",
                    mot: "santiago"
                }

            ]
        },  {
            id: 11,
            titre: 'Pyramide de mots Niveau 2.3',
            temps : 120,
            liste: [
                {
                    definition: "Dans la mer mais pas dans la terre",
                    mot: "m"
                },
                {
                    definition: "La première note de la gamme",
                    mot: "do"
                },
                {
                    definition: "Peur du comédien avant d'affronter le public",
                    mot: "trac"
                },
                {
                    definition: "Sport de glace où l'on lance des pierres polies",
                    mot: "curling"
                },
                {
                    definition: "Le mois de la fête des amoureux",
                    mot: "février"
                },
                {
                    definition: "Sentiment de mépris ou d'hostilité des femmes à l'égard des hommes",
                    mot: "misandrie"
                },
                {
                    definition: "Fête qui se situe 40 jours après Pâques",
                    mot: "ascension"
                }
            ]
        },
        {
            id: 6,
            titre: 'Pyramide de mots Niveau 3.1',
            temps : 140,
            liste: [
                {
                    definition: "Elle achève l’aventure",
                    mot: "e"
                },
                {
                    definition: "Bateau transportant des  des véhicules avec leur chargement et des passagers",
                    mot: "ferry"
                },
                {
                    definition: "Lieu sacré dans lequel le prêtre se prépare aux cérémonies et où l'on garde les objets du culte",
                    mot: "sacristie"
                }, {
                    definition: "Épouse d'un duc",
                    mot: "duchesse"
                },
                {
                    definition: "Paysage composé d’herbes et de quelques arbres qu’on trouve surtout en Afrique",
                    mot: "savane"
                },
                {
                    definition: "Lieu plein d'objets entassés sans ordre",
                    mot: "capharnaüm"
                },
                {
                    definition: "L’auteur de Pavane pour une infante défunte ",
                    mot: "ravel"
                }

            ]
        },
        {
            id: 7,
            titre: 'Pyramide de mots Niveau 3.2',
            temps : 140,
            liste: [
                {
                    definition: "Boulette de riz entourée d'une feuille d'algue et garnie de poisson cru, d'omelette, de concombre, etc... ",
                    mot: "maki"
                },
                {
                    definition: "Le peintre des danseuses de l’Opéra de Paris",
                    mot: "degas"
                },
                {
                    definition: "Une pantoufle ou le croisement d’une jument et d’un âne.",
                    mot: "mule"
                },
                {
                    definition: "Corneille a écrit « A vaincre sans péril, on triomphe sans ... » ",
                    mot: "gloire"
                },
                {
                    definition: "Il a écrit « Le Rouge et le Noir »",
                    mot: "stendhal"
                },
                {
                    definition: "Capitale imaginaire de l'Enfer où Satan invoque le conseil des démons",
                    mot: "pandémonium"
                },
                {
                    definition: "Construit pour enfermer le Minotaure, il est difficile d’en trouver la sortie.",
                    mot: "labyrinthe"
                }
            ]
        },
        {
            id: 12,
            titre: 'Pyramide de mots Niveau 3.3',
            temps : 140,
            liste: [
                {
                    definition: "Quelle lettre est la plus répétée dans le nom de la course olympique de 42,195 km ?",
                    mot: "a"
                },
                {
                    definition: "Possède 6 faces numérotées de 1 à 6",
                    mot: "dés"
                },
                {
                    definition: "Mouvement convulsif répété involontairement",
                    mot: "tic"
                },
                {
                    definition: "Le fruit du chêne",
                    mot: "gland"
                },
                {
                    definition: "Ces  sœurs ont inventé une tarte qui porte leur nom.",
                    mot: "tatin"
                },
                {
                    definition: "Ouragan situé dans le Pacifique nord-ouest",
                    mot: "typhon"
                },
                {
                    definition: "Système d'écriture permettant aux personnes malvoyantes de lire un texte",
                    mot: "braille"
                },
                {
                    definition: "Prénom de la femme de Napoléon III",
                    mot: "eugénie"
                }
                
            ]
        },
        {
            id: 4,
            titre: 'Pyramide de mots Niveau 4.1',
            temps : 170,
            liste: [
                {
                    definition: "Elle a croqué la pomme et condamna toutes les femmes à enfanter dans la douleur et les hommes à devoir travailler pour survivre.",
                    mot: "ève"
                },
                {
                    definition: "Costume de la danseuse classique",
                    mot: "tutu"
                },
                {
                    definition: "Fondateur de la psychanalyse",
                    mot: "freud"
                },
                {
                    definition: "Femme qui est l'inspiratrice de quelqu'un",
                    mot: "égérie"
                },
                {
                    definition: "Ville d’Amèrique célèbre pour avoir pendu 19 sorcières.",
                    mot: "salem"
                },
                {
                    definition: "Un des sept péchés capitaux définis par le catholicisme qui se manifeste par une  accumulation de biens et de richesses sans intention de les dépenser un jour.",
                    mot: "avarice"
                },
                {
                    definition: "Sentiment de mépris ou d'hostilité à l'égard des femmes motivé par leur sexe biologique.",
                    mot: "misogynie"
                }
            ]
        },
        {
            id: 8,
            titre: 'Pyramide de mots Niveau 4.2',
            temps : 170,
            liste: [
                {
                    definition: "Il a construit une arche pour sauver sa famille et des animaux.",
                    mot: "noé"
                },
                {
                    definition: "Prénom féminin le plus populaire en 2019 et celui de l’actrice de La La Land",
                    mot: "emma"
                },
                {
                    definition: "Le sport préféré des femmes en France",
                    mot: "marche"
                },
                {
                    definition: "Étape biologique de grands changements entre l'enfance et l'adolescence ",
                    mot: "puberté"
                },
                {
                    definition: "Personne qui manifeste de l'aversion pour le genre humain",
                    mot: "misanthrope"
                },
                {
                    definition: "On peut le caresser ou monter dessus",
                    mot: "mamelon"
                },
                {
                    definition: "Se dit de mots et de verbes que l'on peut substituer l'un à l'autre dans un énoncé sans changer le sens de celui-ci par exemple bateau et navire.",
                    mot: "synonymes"
                }
            ]
        },
        {
            id: 9,
            titre: 'Pyramide de mots Niveau 4.3',
            temps : 170,
            liste: [
                {
                    definition: "Matériel indispensable pour cuisiner en Chine",
                    mot: "wok"
                },
                {
                    definition: "Vêtement traditionnel des femmes musulmanes, qui dissimule leur corps de la tête aux pieds.",
                    mot: "burqa"
                },
                {
                    definition: "Tour qui voulait toucher le ciel pour égaler Dieu",
                    mot: "babel"
                },
                {
                    definition: "Char allemand",
                    mot: "panzer"
                },
                {
                    definition: "Ce chanteur a connu une fille aux yeux révolver.",
                    mot: "lavoine"
                },
                {
                    definition: "Système politique fondé sur la suppression de la propriété privée au profit de la propriété collective",
                    mot: "communisme"
                },
                {
                    definition: "3 sont normalement nécessaires pendant 9 mois.",
                    mot: "échographies"
                }
            ]
        },
        {
            id: 10,
            titre: 'Pyramide très difficile',
            temps : 170,
            liste: [
                {
                    definition: "Mesure de l'intelligence",
                    mot: "qi"
                },
                {
                    definition: "Poisson et débit de boisson",
                    mot: "bar"
                },
                {
                    definition: "Les Chinois l'apprécient sous forme de bijou.",
                    mot: "jade"
                },
                {
                    definition: "Langue parlée en Belgique",
                    mot: "wallon"
                },
                {
                    definition: "Se dit d'un individu qui ne peut pas savoir avec certitude si un ou des dieux existent.",
                    mot: "agnostique"
                },
                {
                    definition: "État d’indifférence généralisée qui se manifeste par une perte de motivation, de désirs et d’émotions.",
                    mot: "apathie"
                },
                {
                    definition: "Action de tenir quelqu'un qui ne plaît pas à l'écart d'un groupe d'une manière discriminatoire et injuste.",
                    mot: "ostracisme"
                },
                {
                    definition: "Relations sexuelles hors mariage, péché de chair.",
                    mot: "fornication"
                }
            ]
        },
        {
            id: 13,
            titre: 'Pyramide divertissement (cinéma, musique...)',
            temps : 150,
            liste: [
                {
                    definition: "Chef d'oeuvre de Costa-Gavras en 1968.",
                    mot: "z"
                },
                {
                    definition: "Il enseigne la force à Luke Skywalker sur la planète Dagobah.",
                    mot: "yoda"
                },
                {
                    definition: "Chanteur et tennisman",
                    mot: "noah"
                },
                {
                    definition: "Plombier mousatchu qui saute partout.",
                    mot: "mario"
                },
                {
                    definition: "Dans ce film Jake Sully un ancien marine immobilisé dans un fauteuil roulant se rend sur la planète Pandora.",
                    mot: "avatar"
                },
                {
                    definition: "Ce cyborg vient du futur pour tuer la mère d'un enfant qui n'est pas encore né.",
                    mot: "terminator"
                },
                {
                    definition: "Cet écrivain a imaginé le monde de la terre du Milieu où vit le hobbit Frodon Sacquet.",
                    mot: "tolkien"
                },
                {
                    definition: "Nom de l'actrice qui a joué dans Basic Instinct, Total Recall et Casino.",
                    mot: "stone"
                }
            ]
        },
        {
            id: 16,
            titre: '1 image - 1 mot - facile 1',
            temps : 100,
            liste: [
                {
                    definition: "lmot11.jpg",
                    mot: "hiver"
                },
                {
                    definition: "lmot12.jpg",
                    mot: "rose"
                },
                {
                    definition: "lmot13.jpg",
                    mot: "salade"
                },
                {
                    definition: "lmot14.jpg",
                    mot: "cactus"
                },
                {
                    definition: "lmot15.jpg",
                    mot: "botte"
                }
              
            ]
        },
        {
            id: 17,
            titre: '1 image - 1 mot - facile 2',
            temps : 100,
            liste: [
                {
                    definition: "lmot21.jpg",
                    mot: "roi"
                },
                {
                    definition: "lmot22.jpg",
                    mot: "thé"
                },
                {
                    definition: "lmot23.jpg",
                    mot: "poker"
                },
                {
                    definition: "lmot24.jpg",
                    mot: "désert"
                },
                {
                    definition: "lmot25.jpg",
                    mot: "artichaut"
                },
                {
                    definition: "lmot26.jpg",
                    mot: "partition"
                }
              
            ]
        },
        {
            id: 18,
            titre: '1 image - 1 mot - moyen 1',
            temps : 100,
            liste: [
                {
                    definition: "lmot31.jpg",
                    mot: "orgue"
                },
                {
                    definition: "lmot32.jpg",
                    mot: "balcon"
                },
                {
                    definition: "lmot33.jpg",
                    mot: "palmier"
                },
                {
                    definition: "lmot36.jpg",
                    mot: "tacos"
                },
                {
                    definition: "lmot35.jpg",
                    mot: "geisha"
                },
                {
                    definition: "lmot34.jpg",
                    mot: "vignoble"
                },
                
               
              
            ]
        },
      
       
    ]



export default DonneesListeMots;
