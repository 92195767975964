

const DonneePhraseErreur = [
    {
        id : 1,
        niveau: "très facile",
        titre : "Trouver les fautes niveau CE1",
        texte : "Alors cette année, les vacances, c’est la plage en Bretagne. Pour consoler@consolé@ maman, papa a quand même@méme@ dit qu’on ferait du sport. Je crois qu’il a prévu le grand tour du parasol et des randonnées@randonnés@ régulières entre la mer et les serviettes@servietes@ de bain."  
 
    },
    {
        id : 2,
        niveau : "facile",
        titre : "Trouver toutes les fautes niveau CE1",
        texte : "Il était une fois, en Afrique, un lapin qui était très sage. Il avait creusé son terrier au pied d’un vieux baobab, en plein centre de la forêt@foret@. C’était l’ami de tous les animaux, et les singes, les lions, les gazelles, les crocodiles, les perroquets, l’éléphant même, venaient@venait@ se confier à lui. " 
    },
    {
        id : 3,
        niveau : "facile",
        titre : "Trouver toutes les fautes niveau CM2",
        texte : "Il faut une heure environ pour gravir ces@ses@ trois cents@cent@ mètres. Depuis quelque temps, déjà, des vapeurs de soufre@souffre@ nous prennent à la gorge. Nous avons aperçu, tantôt sur la droite, tantôt@tantot@ sur la gauche, de grands jets de fumée sortant par des fissures du sol ; nous avons posé nos mains sur de grosses pierres brulantes." 
    },
    {
        id : 4,
        niveau : "facile",
        titre : "Trouver les fautes niveau CM2",
        texte : "Le vieux Joseph, tout heureux d’avoir les pieds au sec, se laissa réparer et transformer en maison. On lui mit de beaux rideaux, on planta des rosiers qui grimpèrent sur son ventre et on installa de jolies chambres où la petite fille put@pu@ inviter à@a@ jouer tous ses@ces@ petits camarades. Le vieux Joseph devint ainsi la maison des enfants." 
    },
    {
        id : 10,
        niveau : "facile",
        titre : "Vingt-quatre heures de la vie d'une femme (facile)",
        extrait : "Vingt-quatre heures de la vie d'une femme - Zweig",
        texte : "Jamais encore, je n’avais vu un visage dans lequel la passion du jeu jaillissait si bestiale dans sa nudité effrontée@effronté@…. J’étais fascinée par ce visage qui, soudain, devint morne et éteint@éteind@ tandis que la boule se fixait sur un numéro : cet homme venait de tout perdre ! Il s’élança hors du Casino. Instinctivement, je le suivis... Commencèrent alors 24 heures qui allaient@allait@ bouleverser mon destin."
    },
    {
        id : 11,
        niveau : "facile",
        titre : "Nord et Sud (facile)",
        extrait : "Nord et Sud - Elizabeth Gaskell",
        texte : "Il s'approcha encore et à nouveau répéta d'une voix suppliante@supliante@ et frémissante : Margaret ! Elle baissa encore la tête presque jusqu'à la table. Il s'approcha davantage, s'agenouilla à côté d'elle afin de murmurer à son oreille d'une voix haletante : Prenez garde... Si vous ne dites rien j'aurai la présomption@présemption@ de croire que vous acceptez d'être mienne. Si je dois partir, renvoyez-moi tout de suite... Margaret !... À ce troisième appel, elle tourna vers lui son visage à demi@demie@ caché par ses fines mains blanches et elle s'inclina sur son épaule pour s'y blottir@blotir@."  
    },
    {
        id : 13,
        niveau : "facile",
        titre : "La dictée du Balfroid (Belgique) (facile)",
        texte : "Tout le temps, on nous rappelle de quelle@quel@ façon nous devrions manger et ce qu’il faudrait consommer@consomer@. Il faut tout relativiser. Si nos grands-parents n’entendaient pas ce genre de propos, c’est qu’ils utilisaient, au moins à la campagne, des produits naturels. Les poules qu’ils avaient élevées@élevés@ leur donnaient@donnait@ des oeufs frais. Le lait et la viande étaient fournis@fourni@ par les vaches. Le jardin donnait de délicieux légumes : des poireaux, des carottes, du céleri, du persil, tous cueillis@cueilli@ juste avant les préparations culinaires. Maintenant, un circuit court permet d’agir comme autrefois. Il faut peut-être se déplacer vers une ferme proche, mais quel@quelle@ bonheur de déguster des aliments de qualité !"  
    },
   
    {
        id : 6,
        niveau: "intermédiaire",
        titre : "Test de fautes d'orthographe niveau brevet (intermédiaire)",
        texte : " Ah ! les bois, les chers bois de Montigny ! À cette heure-ci, je le sais bien, comme ils@il@ bourdonnent@bourdonne@ ! Les guêpes et les mouches qui pompent dans les fleurs des tilleuls et des sureaux@surots@ font vibrer toute la forêt comme un orgue ; et les oiseaux ne chantent pas, car à midi ils se tiennent debout sur les branches, cherchent l’ombre, lissent leurs@leur@ plumes@plume@, et regardent@regarde@ le sous-bois avec des yeux mobiles et brillants." 
    },
 
    {
        id : 8,
        niveau : "intermédiaire",
        titre : "Trouver les fautes (intermédiaire)",
        texte : "Il était huit heures et demie passées@passée@ quand j’ai enfin pu me coucher. Je me suis réveillée neuf heures plus tard, la tête lourde, car j’avais fait un affreux cauchemar@cauchemard@. Après l’avalanche de paroles@parole@ du chauffeur de taxi et du sapeur-pompier qui m’avaient assommée et laissée quasi traumatisée, j’ai subi en plus une ingestion@injestion@ forcée de nourriture. En effet, j’ai rêvé qu’après une randonnée sur le plateau de Millevaches et l’exploration spéléologique@spélélogique@ d’un aven, j’étais entrée dans un restaurant d’un village du causse@cosse@ corrézien."
    },
    {
        id : 7,
        niveau : "difficile",
        titre : "Notre-Dame de Paris (difficile)",
        extrait : "Notre-Dame de Paris - Hugo",
        texte : "Tous les yeux s’étaient levés vers le haut de l’église. Ce qu’ils@il@ voyaient@voyait@ était extraordinaire. Sur le sommet de la galerie la plus élevée, plus haut que la rosace centrale@central@, il y avait une grande flamme qui montait entre les deux clochers avec des tourbillons d’étincelles, une grande flamme désordonnée et furieuse dont le vent emportait par moments@moment@ un lambeau dans la fumée." 
    },
   
    {
        id : 5,
        niveau : "difficile",
        titre : "Test de fautes d'orthographe niveau brevet (difficile)",
        texte : "J’étais toute tremblante d’une admiration, d’un enthousiasme que j’eusse voulu lui exprimer@exprimé@, mais il ne me venait à l’esprit que des phrases que je craignais qu'elle ne trouvât ridicules@ridicule@. La classe était près de finir. Vite, je déchirai le bas d’une feuille de mon cahier ; j'écrivis en tremblant sur ce bout de papier : ' Je voudrais être votre amie ' et glissai@glissais@ vers elle gauchement ce billet. " 
    },
    {
        id : 9,
        niveau: "très difficile",
        titre : "Trouver les fautes (très difficile)",
        texte : "Hier, un collègue est tombé d’un appentis@apentis@ en capturant un ara@aras@ qui le narguait en le traitant de bachibouzouk. Le pauvre s’est brisé le coccyx@cocyx@. Il y a deux jours, c’était la traque d’un atèle@athèle@, propriété d’une ex-égérie quasi centenaire de Soljenitsyne@Soljenitsine@. Le singe s’était réfugié au milieu des panicules les plus fournies d’un paulownia et s’enivrait de l’odeur de violette exhalée par les fleurs mellifères@melifères@. C’est au lasso qu’on l’a ramené à terre à@a@ passé deux heures du matin."
    },
 
    {
        id : 12,
        niveau : "très difficile",
        titre : "Le texte de Mérimée (très très difficile)",
        texte : "Quelles que soient@soit@ et quelqu'exiguës qu'aient pu paraître, à côté de la somme due, les arrhes@arhes@ qu'étaient censés@censées@ avoir données@donnés@ la douairière et le marguillier, il était infâme d'en vouloir pour cela à ces fusiliers jumeaux et mal bâtis et de leur infliger une raclée alors qu'ils ne songeaient qu'à prendre des rafraîchissements avec leurs coreligionnaires@co-religionnaires@."  
    },
    {
        id : 16,
        niveau : "intermédiaire",
        titre : "Extrait du test de positionnement en début de lycée par l'Éducation nationale",
        texte : "Déçue@Déçu@ par l’attitude de Thierry, elle ne veut plus lui parler. <br> Appréciée par le public, la chanson a été interprétée une nouvelle fois. <br> Heureuses@Heureuse@, les petites filles font de la balançoire. <br> Impatients@Impatient@, les premiers acheteurs se précipitèrent dans le magasin. <br> Elle a déjà fini@finit@ son assiette de frites. <br>  Sous le soleil de l’été, étendue@étendu@ sur sa serviette, Marie se repose enfin. <br> On se@ce@ réveille sans savoir le temps qu’il fait et on se lève pour regarder par la fenêtre."
    },
    // {
    //     id : 17,
    //     niveau : "intermédiaire",
    //     titre : "La souris prisonnière",
    //     texte : "Papa attrapait les souris toutes vivantes@vivante@ dans une espèce de boîte qui se fermait@fermaient@ sur elles@elle@ en claquant et dont elles ne pouvaient sortir. J'allais les regarder là, par leur petite fenêtre@fenetre@ grillagée. Je voyais une jolie@joli@ petite bête avec de jolis petits yeux vifs, et j'aurais bien voulu la tenir dans ma main. Mais, dans la boîte, elle@elles@ courait@couraient@ de tous les côtés pour trouver la porte."
    // },

];
export default  DonneePhraseErreur
