

const DonneeVerbeConjugaison = [
    {
        id: 1,
        titre: "Conjugaison au présent",
        exo: [
            {
                temps: 'Présent',
                sujet: 'je',
                verbe: 'penser',
                reponse: 'pense'
            },
            {
                temps: 'Présent',
                sujet: 'il',
                verbe: 'finir',
                reponse: 'finit'
            },
            {
                temps: 'Présent',
                sujet: 'nous',
                verbe: 'être',
                reponse: 'sommes'
            },
            {
                temps: 'Présent',
                sujet: 'elle',
                verbe: 'envoyer',
                reponse: 'envoie'
            },
            {
                temps: 'Présent',
                sujet: 'ils',
                verbe: 'aimer',
                reponse: 'aiment'
            },
            {
                temps: 'Présent',
                sujet: 'tu',
                verbe: 'battre',
                reponse: 'bats'
            }

        ]
    },
    {
        id: 2,
        titre: "Conjugaison au passé composé",
        exo: [
            {
                temps: 'Passé composé',
                sujet: 'j\'',
                verbe: 'conjuguer',
                reponse: 'ai conjugué'
            },
            {
                temps: 'Passé composé',
                sujet: 'il',
                verbe: 'sécher',
                reponse: 'a séché'
            },
            {
                temps: 'Passé composé',
                sujet: 'vous',
                verbe: 'installer',
                reponse: 'avez installé'
            },
            {
                temps: 'Passé composé',
                sujet: 'nous',
                verbe: 'prendre',
                reponse: 'avons pris'
            },
            {
                temps: 'Passé composé',
                sujet: 'ils',
                verbe: 'envahir',
                reponse: 'ont envahi'
            },
            {
                temps: 'Passé composé',
                sujet: 'nous',
                verbe: 'fuire',
                reponse: 'avons fui'
            }
        ]
    },
    {
        id: 3,
        titre: "Conjugaison au passé simple",
        exo: [
            {
                temps: 'Passé simple',
                sujet: 'vous',
                verbe: 'couler',
                reponse: 'coulâtes'
            },
            {
                temps: 'Passé simple',
                sujet: 'tu',
                verbe: 'manger',
                reponse: 'mangeas'
            },
            {
                temps: 'Passé simple',
                sujet: 'nous',
                verbe: 'profiter',
                reponse: 'profitâmes'
            },
            {
                temps: 'Passé simple',
                sujet: 'je',
                verbe: 'faire',
                reponse: 'fis'
            },
            {
                temps: 'Passé simple',
                sujet: 'ils',
                verbe: 'jouer',
                reponse: 'jouèrent'
            },
            {
                temps: 'Passé simple',
                sujet: 'nous',
                verbe: 'fuir',
                reponse: 'fuîmes'
            }
        ]

    },
    {
        id: 4,
        titre: "Conjugaison au conditionnel présent",
        exo: [
            {
                temps: 'Conditionnel présent',
                sujet: 'ils',
                verbe: 'bouger',
                reponse: 'bougeraient'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'tu',
                verbe: 'informer',
                reponse: 'informerais'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'nous',
                verbe: 'taire',
                reponse: 'tairions'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'ils',
                verbe: 'réussir',
                reponse: 'réussiraient'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'ils',
                verbe: 'perdre',
                reponse: 'perdraient'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'tu',
                verbe: 'appréhender',
                reponse: 'appréhenderais'
            }
        ]

    },
    {
        id: 5,
        titre: "Conjugaison impératif présent",
        exo: [
            {
                temps: 'Impératif présent',
                sujet: '(tu)',
                verbe: 'gagner',
                reponse: 'gagne'
            },
            {
                temps: 'Impératif présent',
                sujet: '(tu)',
                verbe: 'convier',
                reponse: 'convie'
            },
            {
                temps: 'Impératif présent',
                sujet: '(tu)',
                verbe: 'avoir',
                reponse: 'aie'
            },
            {
                temps: 'Impératif présent',
                sujet: '(nous)',
                verbe: 'avoir',
                reponse: 'ayons'
            },
            {
                temps: 'Impératif présent',
                sujet: '(vous)',
                verbe: 'avoir',
                reponse: 'ayez'
            },
            {
                temps: 'Impératif présent',
                sujet: '(vous)',
                verbe: 'râler',
                reponse: 'râlez'
            }
        ]

    },
    {
        id: 6,
        titre: "Conjugaison infinitif et gérondif",
        exo: [
            {
                temps: 'Infinitif',
                sujet: '',
                verbe: 'gagner',
                reponse: 'gagner'
            },
            {
                temps: 'Infinitif passé',
                sujet: '',
                verbe: 'ajouter',
                reponse: 'avoir ajouté'
            },
            {
                temps: 'Gérondif présent',
                sujet: '',
                verbe: 'procurer',
                reponse: 'en procurant'
            },
            {
                temps: 'Gérondif passé',
                sujet: '',
                verbe: 'procurer',
                reponse: 'en ayant procuré'
            },
            {
                temps: 'Gérondif présent',
                sujet: '',
                verbe: 'déteindre',
                reponse: 'en déteignant'
            },
            {
                temps: 'Gérondif passé',
                sujet: '',
                verbe: 'râler',
                reponse: 'en ayant râlé'
            }
        ]

    },
    {
        id: 7,
        titre: "Conjugaison des verbes irréguliers",
        exo: [
            {
                temps: 'Présent',
                sujet: 'je',
                verbe: 'peindre',
                reponse: 'peins'
            },
            {
                temps: 'Futur',
                sujet: 'je',
                verbe: 'battre',
                reponse: 'battrai'
            },
            {
                temps: 'Imparfait',
                sujet: 'ils',
                verbe: 'déplaire',
                reponse: 'déplaisaient'
            },
            {
                temps: 'Plus-que-parfait',
                sujet: 'il',
                verbe: 'joindre',
                reponse: 'avait joint'
            },
            {
                temps: 'Passé simple',
                sujet: 'ils',
                verbe: 'pouvoir',
                reponse: 'purent'
            },
            {
                temps: 'Futur',
                sujet: 'je',
                verbe: 'connaître',
                reponse: 'connaîtrai'
            }
        ]

    }, 
    {
        id: 8,
        titre: "Conjugaisons très difficiles",
        exo: [
            {
                temps: 'Futur antérieur',
                sujet: 'ils',
                verbe: 'dérouler',
                reponse: 'auront déroulé'
            },
            {
                temps: 'Passé antérieur',
                sujet: 'tu',
                verbe: 'moudre',
                reponse: 'eus moulu'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'je',
                verbe: 'joindre',
                reponse: 'joindrais'
            },
            {
                temps: 'Subjonctif présent',
                sujet: 'que tu',
                verbe: 'requérir',
                reponse: 'requières'
            },
            {
                temps: 'Passé simple',
                sujet: 'ils',
                verbe: 'rompre',
                reponse: 'rompirent'
            }, {
                temps: 'Futur antérieur',
                sujet: 'nous',
                verbe: 'acquérir',
                reponse: 'aurons acquis'
            }

        ]

    }, 
    {
        id: 9,
        titre: "Test futur vs conditionnel",
        exo: [
            {
                temps: 'Futur',
                sujet: 'je',
                verbe: 'consolider',
                reponse: 'consoliderai'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'je',
                verbe: 'consolider',
                reponse: 'consoliderais'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'tu',
                verbe: 'devoir',
                reponse: 'devrais'
            },
            {
                temps: 'Futur',
                sujet: 'tu',
                verbe: 'devoir',
                reponse: 'devras'
            },
            {
                temps: 'Conditionnel présent',
                sujet: 'vous',
                verbe: 'sentir',
                reponse: 'sentiriez'
            },
            {
                temps: 'Futur',
                sujet: 'vous',
                verbe: 'sentir',
                reponse: 'sentirez'
            }
        ]

    }
    , 
    {
        id: 10,
        titre: "Conjugaison verbe premier groupe présent",
        exo: [
            {
                temps: 'Présent',
                sujet: 'je',
                verbe: 'parler',
                reponse: 'parle'
            },
            {
                temps: 'Présent',
                sujet: 'tu',
                verbe: 'parler',
                reponse: 'parles'
            },
            {
                temps: 'Présent',
                sujet: 'elle',
                verbe: 'parler',
                reponse: 'parle'
            },
            {
                temps: 'Présent',
                sujet: 'nous',
                verbe: 'parler',
                reponse: 'parlons'
            },
            {
                temps: 'Présent',
                sujet: 'vous',
                verbe: 'parler',
                reponse: 'parlez'
            },
            
            {
                temps: 'Présent',
                sujet: 'ils',
                verbe: 'parler',
                reponse: 'parlent'
            }
        ]

    },
    {
        id: 11,
        titre: "Conjugaison verbe second groupe présent",
        exo: [
            {
                temps: 'Présent',
                sujet: 'je',
                verbe: 'bondir',
                reponse: 'bondis'
            },
            {
                temps: 'Présent',
                sujet: 'tu',
                verbe: 'bondir',
                reponse: 'bondis'
            },
            {
                temps: 'Présent',
                sujet: 'elle',
                verbe: 'bondir',
                reponse: 'bondit'
            },
            {
                temps: 'Présent',
                sujet: 'nous',
                verbe: 'bondir',
                reponse: 'bondissons'
            },
            {
                temps: 'Présent',
                sujet: 'vous',
                verbe: 'bondir',
                reponse: 'bondissez'
            },
            {
                temps: 'Présent',
                sujet: 'elles',
                verbe: 'bondir',
                reponse: 'bondissent'
            }
        ]
    }
    ,
    {
        id: 12,
        titre: "Conjugaison verbe troisième groupe présent",
        exo: [
            {
                temps: 'Présent',
                sujet: 'je',
                verbe: 'sentir',
                reponse: 'sens'
            },
            {
                temps: 'Présent',
                sujet: 'tu',
                verbe: 'sentir',
                reponse: 'sens'
            },
            {
                temps: 'Présent',
                sujet: 'elle',
                verbe: 'sentir',
                reponse: 'sent'
            },
            {
                temps: 'Présent',
                sujet: 'nous',
                verbe: 'sentir',
                reponse: 'sentons'
            },
            {
                temps: 'Présent',
                sujet: 'vous',
                verbe: 'sentir',
                reponse: 'sentez'
            },
            {
                temps: 'Présent',
                sujet: 'elles',
                verbe: 'sentir',
                reponse: 'sentent'
            }
        ]
    } ,
    {
        id: 13,
        titre: "Conjugaison verbes coudre/moudre",
        exo: [
            {
                temps: 'Présent',
                sujet: 'je',
                verbe: 'coudre',
                reponse: 'couds'
            },
            {
                temps: 'Futur',
                sujet: 'tu',
                verbe: 'coudre',
                reponse: 'coudras'
            },
            {
                temps: 'Imparfait',
                sujet: 'elle',
                verbe: 'coudre',
                reponse: 'cousait'
            },
            {
                temps: 'Passé composé',
                sujet: 'nous',
                verbe: 'moudre',
                reponse: 'avons moulu'
            },
            {
                temps: 'Plus-que-parfait',
                sujet: 'vous',
                verbe: 'moudre',
                reponse: 'aviez moulu'
            },
            {
                temps: 'Passé simple',
                sujet: 'elles',
                verbe: 'moudre',
                reponse: 'moulurent'
            }
        ]
    } ,
    {
        id: 14,
        titre: "Conjugaison verbes difficiles",
        exo: [
            {
                temps: 'Futur',
                sujet: 'je',
                verbe: 'balayer',
                reponse: 'balaierai'
            },
            {
                temps: 'Passé simple',
                sujet: 'tu',
                verbe: 'cueillir',
                reponse: 'cueillis'
            },
            {
                temps: 'Présent',
                sujet: 'elle',
                verbe: 'clore',
                reponse: 'clôt'
            },
            {
                temps: 'Futur',
                sujet: 'nous',
                verbe: 'craindre',
                reponse: 'craindrons'
            },
            {
                temps: 'Imparfait',
                sujet: 'vous',
                verbe: 'joindre',
                reponse: 'joigniez'
            },
            {
                temps: 'Futur',
                sujet: 'elles',
                verbe: 'naître',
                reponse: 'naîtront'
            }
        ]
    }


];

export default DonneeVerbeConjugaison;
