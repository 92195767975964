const Donnee6Mots = [
    {
        id: 1,
        titre: "Boogle 6 mots - facile 1",
        info:
        {
            lettres: ['R', 'A', 'T','E', 'B', 'P', 'D'],
            motsLongueur : [1,1,1,6]
      
        },
    },
    {
        id: 2,
        titre: "Boogle 6 mots - facile 2",
        info:
        {
            lettres: ['R', 'A', 'T','O', 'B', 'E', 'D'],
            motsLongueur : [1,1,1,6]
      
        },
    },
    {
        id: 3,
        titre: "Boogle 6 mots - facile 3",
        info:
        {
            lettres: ['E', 'A', 'S','I', 'N', 'R', 'T'],
            motsLongueur : [1,1,1,6]
      
        },
    },
    {
        id: 4,
        titre: "Boogle 6 mots - facile 4",
        info:
        {
            lettres: ['D', 'P', 'R','L', 'A', 'E', 'I'],
            motsLongueur : [1,1,1,6]
      
        },
    },
    {
        id: 5,
        titre: "Boogle 6 mots - moyen 1",
        info:
        {
            lettres: ['S', 'B', 'T','R', 'E', 'A', 'M'],
            motsLongueur : [1,1,1,6]
      
        },
    },
    {
        id: 6,
        titre: "Boogle 6 mots - moyen 2",
        info:
        {
            lettres: ['I', 'L', 'R','N', 'E', 'M', 'A'],
            motsLongueur : [1,1,1,6]
      
        },
    },
 
]
export default Donnee6Mots;