const DonneeDesordre = [
    {id: 1,
    titre: "Désordre facile 1",
    liste :
    [{
        lettres : "HUPROETAASIBR",
        reponse : ["BAS","HAUT"]
    },
    {
        lettres : "EUVLRESOVBRREPQEA",
        reponse : ["LOUPE","VERRE"]
    }]
},
{id: 2,
    titre: "Désordre facile 2",
    liste :
    [{
        lettres : "PRITOREEAUIFCS",
        reponse : ["FOIE","CUIR"]
    },
    {
        lettres : "NNDTOEPIESDSETU",
        reponse : ["DENT","DOSE"]
    }]
},
{id: 7,
    titre: "Désordre facile 3",
    liste :
    [{
        lettres : "BONMEINARCPTOD",
        reponse : ["DON","MAIN"]
    },
    {
        lettres : "MGNAMUIIBERTGSREAAVT",
    reponse : ["MUET","MARS","GAIN"]
    }]
},
{id: 8,
    titre: "Désordre facile 4",
    liste :
    [{
        lettres : "IIGWRREMSXUERTAOBR",
        reponse : ["RIRE","ROBE"]
    },
    {
        lettres : "TFRIADSAUFNEHIVM",
    reponse : ["TARD","VAIN"]
    }]
},
{id: 3,
    titre: "Désordre moyen 1",
    liste :
    [{
        lettres : "GSREOFIUTCFSGILHE",
        reponse : ["FLIC","GOSSE"]
    },
    {
        lettres : "SNOAZUALOEEEUTEDYGRRSL",
        reponse : ["RAYON","SALLE","DROGUE"]
    }]
},
{id: 4,
    titre: "Désordre moyen 2",
    liste :
    [{
        lettres : "TDHURGAOEOENUCLCEER",
        reponse : ["DOUANE","ECORCE"]
    },
    {
        lettres : "RAORPMSISIIENTAEFETD",
        reponse : ["EMPIRE","ARTISAN"]
    }]
},
{id: 6,
    titre: "Désordre moyen 3",
    liste :
    [
        {
            lettres : "TIJRROEOIGADDUUER",
            reponse : ["RODEUR","JEUDI"]
        },
        {
        lettres : "STATALDNPEETUHFYOUIIB",
        reponse : ["TAUDIS","BOUT","FILE"]
    },
   ]
},
{id: 9,
    titre: "Désordre moyen 4",
    liste :
    [
        {
            lettres :  "SKTOOPIAEJPLISAICNULU",
            reponse : ["SKI","LOUP","PIANO"]
        },
    {
        lettres : "RMRREVDDIOUREEIPYJASRBOTPROI",
        reponse : ["VIE","JURY","DOPER","DORMIR"]
    },
   ]
},
{id: 5,
    titre: "Désordre difficile 1",
    liste :
    [{
        lettres : "ORCDEPUROEAEVSCCMFHCAL",
        reponse : ["CAMPEUR","CLOCHER"]
    },
    {
        lettres : "CBPVOECRVARCIEOSEETSISNA",
    reponse : ["CONVIVE","CARCASSE"]
    }]
},
// {id: 10,
//     titre: "Désordre concours 1",
//     liste :
//     [{
//         lettres : "LTOLXEPIRGSEOTIAILETNEF",
//       reponse : ["SEXE","FILON","GILET"]
//     },
//     {
//         lettres : "ETVEEFROREIAYMBSJGIP",
//         reponse : ["FOYER","EGERIE"]
//     }]
// },
// {id: 11,
//     titre: "Désordre concours 2",
//     liste :
//     [{
//         lettres : "NECSRCTEIEROVEAEMLPHNSAO",
//         reponse : ["CALE","VEINE","SERMON"]
//     },
//     {
//         lettres : "KRTEETADORGASEBIOEUSCPR",
// reponse : ["ROCKET","ADRESSE"]
//     }]
// },


]
export default DonneeDesordre;